
import { Trip } from '@/models/dto'
import { Quote } from '@/models/dto/Quote'
import { metersToMiles } from '@/utils/distance'
import { currencyFilter, phoneFormatFilter } from '@/utils/string'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import quotes from '@/services/quotes'
import dayjs from 'dayjs'
import { EventBus } from '@/utils/eventBus'
import company from '@/services/company'
import colors from '@/scss/_colors-export.scss'
import { Itinerary } from '@/models/dto/Itinerary'
import { WidgetSubmission } from '@/models/dto/Widget'
import { staticResource } from '@/utils/env'
import quote from '@/store/modules/quote'

@Component({
  components: {
    ReservationTrackingMap,
    TripItinerary,
  },
})
export default class WidgetReferralTripDetail extends Vue {
  @Prop({ required: true }) quote: Quote
  @Prop({ required: false, default: false }) isSelected: boolean

  colors = colors
  acceptLoading = false
  rejectLoading = false
  eventTypes = []

  currencyFilter = currencyFilter
  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource

  get trip(): Trip {
    return this.quote.trips[0]
  }

  get submission(): WidgetSubmission {
    return (
      this.quote?.widgetSubmissions?.[0] ||
      new WidgetSubmission({
        firstName: this.quote?.customer?.firstName,
        lastName: this.quote?.customer?.lastName,
        email: this.quote?.customer?.email,
        phone: this.quote?.customer?.phone,
      })
    )
  }

  get vehicles(): { label: string; quantity: number }[] {
    return this.trip.vehicles
      .filter((v) => !!v.vehicleType)
      .map((v) => ({ label: v.vehicleType.label, quantity: v.quantity }))
  }

  get travelTime(): string {
    const hours = quote?.estimations?.[this.trip.tripId]?.itineraryHours || 0
    const minutes = 0

    return `${hours} h, ${minutes} min`
  }

  get travelDays(): number {
    const drivingTime =
      quote?.estimations?.[this.trip.tripId]?.itineraryHours || 0
    const drivingTimeDuration = dayjs.duration(drivingTime, 'hours')

    return Math.ceil(drivingTimeDuration.asDays())
  }

  get travelDistance(): string {
    return `${metersToMiles(this.trip.distance).toFixed(1)} mi.`
  }

  get requirements(): string {
    return '--'
  }

  get eventName(): string {
    return (
      this.eventTypes.find((e) => e.id === this.quote.eventTypeId)?.label ||
      '--'
    )
  }

  get notes(): string {
    return this.quote?.widgetSubmissions?.map((s) => s.note).join('\n')
  }

  get itineraries(): Itinerary[] {
    return this.quote?.itineraries || []
  }

  async handleAccept(): Promise<void> {
    this.acceptLoading = true
    const result = await quotes.acceptReferral(this.quote.referralId)
    if (result?.data?.isRejected) {
      EventBus.$emit('snackbar:error', 'This lead is no longer available.')
      await this.handleReject()
    } else {
      this.acceptLoading = false
      this.$router.push({ name: 'reservations' })
    }
  }

  async handleReject(): Promise<void> {
    this.rejectLoading = true
    await quotes.rejectReferral(this.quote.referralId)
    this.rejectLoading = false
    this.$emit('reject')
  }

  async loadEventTypes(): Promise<void> {
    const res = await company.getCompanyEventTypes(this.quote.companyId)
    this.eventTypes = res.data?.eventTypes || []
  }

  mounted(): void {
    this.loadEventTypes()
  }
}
