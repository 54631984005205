import dayjs from 'dayjs'
import { Customer } from './Customer'
import { Itinerary } from './Itinerary'
import { Stop } from './Stop'

export interface Address {
  addressId: number
  name?: string
  street1: string
  street2?: string
  city: string
  state: string
  postalCode: string
  createdOn?: dayjs.Dayjs
  updatedOn?: dayjs.Dayjs
  lat: number
  lng: number
  timeZone: string
  title?: string
  nearestMarketId?: number
  companyId?: number
  addressName: string
  completeAddress?: string
}

export interface EventType {
  id: number
  companyId: number
  label: string
  orderIndex: number
}

export interface CompanyType {
  companyTypeId: number
  companyTypeName?: any
  key: string
}

export interface PartnerType {
  partnerTypeId: number
  key: string
  label: string
  description?: string
}

export interface SocialMediaType {
  id: number
  key: string
  label: string
  description?: string
  companyId?: number
  active: boolean
}

export interface SocialLink {
  companySocialLinkId?: number
  uri: string
  typeKey?: string
  typeLabel?: string
  companyId: number
  socialMediaTypeId: number
  socialMediaType?: SocialMediaType
}

export interface Company {
  name: string
  companyId: number
  address: Address
  email: string
  emailSenderName: string
  bccEmail: string
  phone: string
  opsPhone: string
  fax: string
  dotNumber: string
  dotVerified?: boolean
  broker: boolean
  isAdminCompany: boolean
  isClient: boolean
  hasTerms: boolean
  companyType: CompanyType
  websiteUrl?: string
  redirectPageUrl: string
  darkLogoUrl: string
  lightLogoUrl: string
  primaryColor: string
  secondaryColor: string
  signatureRequired: boolean
  ticketCancellationRequired: boolean
  usabilityStatus: boolean
  checkoutTypeId: number
  partnerType: PartnerType
  offeredCount: number
  acceptedCount: number
  rejectedCount: number
  driverCount: number
  parentCompanyId?: any
  garage: boolean
  active: boolean
  linkedAccountProfileId?: number
  socialLinks: SocialLink[]
  ada?: boolean
  spab?: boolean
  paymentMethod?: any
  isTrackingOdometry: boolean
  preferred: boolean
  yearFounded: number
  defaultPricingSelectionId?: number
  defaultDueDate?: number
  defaultExpirationDate?: number
  defaultEnableExpiration?: boolean
  defaultExpirationType?: number
  defaultOverageRateAmount?: number
  defaultOverageRateTypeId?: number
  defaultPaymentPolicy?: string
  defaultPercentageDueNow?: number
  defaultEnablePayLater?: boolean
  defaultPreTripArrivalTime?: string
  defaultPricingMethodTypeId?: number
  showCharterUPIntegration?: boolean
  eventTypes?: EventType[]
}

// eslint-disable-next-line no-redeclare
export class Address implements Address {
  constructor(address?: Partial<Address>) {
    Object.assign(this, address)
  }
}

// eslint-disable-next-line no-redeclare
export class Company implements Company {
  constructor(company?: Company) {
    this.address = new Address()
    Object.assign(this, company)
  }
}

export interface WidgetSubmitPayload {
  customer: Partial<Customer>
  tripTypeId: number
  eventTypeId: number
  passengerCount: number
  stops: Stop[]
  vehicles: {
    vehicleTypeId?: number
    quantity: number
  }[]
  note: string
  itineraries?: Itinerary[]
  token: string
  tag: string
}

export interface WidgetSubmission {
  firstName: string
  lastName: string
  email: string
  phone: string
  note: string
  company: string
  companyId: number
  createdAt: string
  quoteId: number
}

// eslint-disable-next-line no-redeclare
export class WidgetSubmission implements WidgetSubmission {
  constructor(submission?: Partial<WidgetSubmission>) {
    Object.assign(this, submission)
  }
}
