
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { GarageTimes } from '@/models/dto/GarageTimes'
import { Garage } from '@/models/dto/Garage'
import { FormattedNote } from '@/models/dto/Note'
import { getWidthOfTextInFont } from '@/utils/string'
import auth from '@/store/modules/auth'

@Component({})
export default class TripItineraryGarageCard extends mixins(DateMixin) {
  @Prop({ required: true }) readonly garageTimes!: GarageTimes
  @Prop({ type: Boolean, default: false }) readonly noLine!: boolean
  @Prop({ type: Boolean, default: false }) readonly returning!: boolean

  expanded = false
  canvas = null
  isMounted = false
  debounce = null
  forceRefresh = 0
  addressWidth = null

  get lineHeight(): string {
    return this.noLine ? '0px' : '100%'
  }

  get garage(): Garage {
    return !this.returning
      ? this.garageTimes?.garage
      : this.garageTimes?.returnGarage
  }

  get notes(): string {
    return !this.returning
      ? this.garageTimes?.htmlGarageTimeNotes ||
          this.garageTimes?.garageTimeNotes
      : this.garageTimes?.htmlReturnGarageTimeNotes ||
          this.garageTimes?.returnGarageTimeNotes
  }

  get title(): string {
    return this.garage?.name || this.garage?.garageName || this.fallbackTitle
  }

  get fallbackTitle(): string {
    return !this.returning ? 'Departure Garage' : 'Arrival Garage'
  }

  get timestamp(): string {
    return !this.returning
      ? this.garageTimes?.departureTime
      : this.garageTimes?.returnTime
  }

  get isDatetimeValid(): boolean {
    return !!this.date && !!this.time
  }

  get date(): string {
    return !this.timestamp
      ? ''
      : this.formatShortDate(this.timestamp, { tz: this.timeZone })
  }

  get time(): string {
    return !this.timestamp
      ? ''
      : this.formatShortTime(this.timestamp, {
          tz: this.timeZone,
          showTimezone: true,
          showMeridianUpper: true,
        })
  }

  get timeZone(): string {
    return this.garage?.address?.timeZone || auth.getUserTimeZone
  }

  get arrivalTime(): string {
    return !this.garageTimes?.preTripArrivalTime || this.returning
      ? ''
      : this.formatShortTime(this.garageTimes?.preTripArrivalTime, {
          tz: this.timeZone,
          showTimezone: true,
          showMeridianUpper: true,
        })
  }

  get ref(): string {
    return `itinerary-note-${this.returning ? 1 : 0}`
  }

  get fullStopNote(): string {
    return !this.returning
      ? this.garageTimes?.htmlGarageTimeNotes ||
          this.garageTimes?.garageTimeNotes ||
          ''
      : this.garageTimes?.htmlReturnGarageTimeNotes ||
          this.garageTimes?.returnGarageTimeNotes ||
          ''
  }

  get formattedNotes(): FormattedNote {
    const r = this.forceRefresh
    const clearNewLines = /<[ul,li,/ul,/li,p,/p,br]*>/g
    if (!this.garage || !this.isMounted) {
      return null
    }
    const note = !this.returning
      ? this.garageTimes?.htmlGarageTimeNotes ||
        this.garageTimes?.garageTimeNotes ||
        ''
      : this.garageTimes?.htmlReturnGarageTimeNotes ||
        this.garageTimes?.returnGarageTimeNotes ||
        ''
    return {
      canExpand: this.canExpand(note),
      notes: note.replaceAll(clearNewLines, ' '),
    }
  }

  canExpand(note: string): boolean {
    if (!note) {
      return false
    }
    return (
      getWidthOfTextInFont(
        note.replaceAll(/<[^>]*>/g, ''),
        '14px',
        '400',
        this.canvas
      ) >
        this.addressWidth + 24 || note.includes('<ul>')
    )
  }

  handleShowNote(note: FormattedNote): void {
    this.expanded = note.canExpand && !this.expanded
  }

  get simpleNote(): string {
    return this.formattedNotes?.notes || ''
  }

  handleResize(): void {
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(() => {
      this.forceRefresh += 1
      const newWidth = (this.$refs[this.ref] as any)?.scrollWidth
      if (newWidth) {
        this.addressWidth = newWidth
      }
    }, 250)
  }

  mounted(): void {
    window.addEventListener('resize', this.handleResize)
    this.isMounted = true
    this.addressWidth = (this.$refs[this.ref] as any)?.scrollWidth || 0
  }
}
