
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { buildMessage } from '@/utils/message'
import { busifyPayBaseUrl } from '@/utils/env'
import reservation from '@/services/reservation'
import { PaymentMethod } from '@/models/dto'
import currency from 'currency.js'
import { debounce } from 'lodash'
import { WindowMessage } from '@/models/Message'

@Component
export default class BusifyPayReservationPaymentForm extends Vue {
  @Prop({ required: true }) reservationIds!: number[]
  @Prop({ required: true }) paymentMethod!: PaymentMethod
  @Prop({ required: true }) sessionId!: string
  @Prop({ required: true }) amountAsCents!: number | string
  @Prop({ required: false, default: 0 }) processingFeePercentage!: number
  @Prop({ required: true }) gatewayApiKey!: string
  @Prop({ type: Boolean, default: false }) showSavedPaymentInstruments!: boolean
  @Prop({ required: true }) customerId!: number

  get paymentParameters(): Record<string, string | PaymentMethod | number> {
    return {
      reservationIds: this.reservationIds.join(),
      sessionId: this.sessionId,
      paymentMethod: this.paymentMethod,
      amount: String(this.amountAsCents),
      processingFeePercentage: this.processingFeePercentage.toString(),
      customerId: this.customerId,
    }
  }

  src = ''
  displayError = false
  isMaxTransactionError = true
  height = 434

  handleMessageEvent(event: MessageEvent): void {
    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }

    if (data.messageName === 'height-updated') {
      const { height } = data.messageData
      this.height = height as number
    }
  }

  submit(): void {
    const iframe = this.$refs.iframe as any
    const message = buildMessage(this.sessionId, 'submit-form')
    iframe.contentWindow.postMessage(message, busifyPayBaseUrl())
  }

  refresh(): void {
    window.location.reload()
  }

  loadPaymentFormFromParameters = debounce(async function () {
    const amount = currency(this.amountAsCents).divide(100).value

    try {
      const res = await reservation.initializeReservationPayment(
        this.reservationIds,
        amount,
        this.paymentMethod,
        this.processingFeePercentage,
        this.sessionId,
        this.gatewayApiKey
      )

      if (res.status !== 200) {
        throw new Error(res)
      } else {
        this.displayError = false
      }

      const currentTime = new Date().getTime()

      this.src = `${res.data.data}&showSavedPaymentInstruments=${this.showSavedPaymentInstruments}&customerId=${this.customerId}&timestamp=${currentTime}`
    } catch (e: any) {
      if (e.response?.data?.message?.includes('maximum transaction')) {
        this.isMaxTransactionError = true
      } else {
        this.isMaxTransactionError = false
      }
      this.displayError = true
    }
  }, 500)

  @Watch('paymentParameters', { immediate: true, deep: true })
  onParameterChange(): void {
    this.loadPaymentFormFromParameters()
  }

  mounted(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }
}
