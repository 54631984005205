
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CalendarEvent } from '@/models/Calendar'
import { Recurrence } from '@/models/dto/Recurrence'
import { Trip } from '@/models/dto'
import CUCalendar from '@/components/CUCalendar.vue'
import CURecurrencePicker from '@/components/CURecurrencePicker.vue'
import quote from '@/store/modules/quote'
import dayjs from 'dayjs'
import quoteService from '@/services/quotes'
import deepClone from '@/utils/deepClone'
import auth from '@/store/modules/auth'

@Component({
  components: { CUCalendar, CURecurrencePicker },
})
export default class TripRecurrence extends Vue {
  @Prop({ required: true }) readonly tripIdx!: number
  state = quote
  eventDateList = []

  get tripCount(): number {
    if (!this.calendarEvents.length) {
      return 1
    }
    return this.calendarEvents.length
  }

  get timeZone(): string {
    return this.trip.stops?.[0]?.address?.timeZone || auth.getUserTimeZone
  }

  get isRecurring(): boolean {
    return !!this.trip.hasRecurrence
  }

  get trip(): Trip {
    return this.state.trips[this.tripIdx]
  }

  get recurrenceStartDate(): string {
    if (!this.recurrence.startDate) {
      return ''
    }
    return dayjs(this.recurrence.startDate)
      .tz(this.timeZone)
      .format('YYYY-MM-DDTHH:mm:ss')
  }

  get recurrenceEndDate(): string {
    if (!this.recurrence.endDate) {
      return ''
    }
    return dayjs(this.recurrence.endDate).tz(this.timeZone).format('YYYY-MM-DD')
  }

  get tripEndDate(): string {
    const stops = this.trip.stops.filter(
      (stop) => stop.pickupDatetime || stop.dropoffDatetime
    )
    const lastStop = stops[stops.length - 1]
    return lastStop?.dropoffDatetime || lastStop?.pickupDatetime
  }

  get tripStartTimezone(): string {
    const timeZones = this.trip.stops
      .filter((stop) => !!stop.address?.timeZone)
      .map((stop) => stop.address.timeZone)
    return timeZones[0] || this.timeZone
  }

  get tripEndTimezone(): string {
    const timeZones = this.trip.stops
      .filter((stop) => !!stop.address?.timeZone)
      .map((stop) => stop.address.timeZone)
    return timeZones[timeZones.length - 1] || this.timeZone
  }

  get calendarEvents(): CalendarEvent[] {
    if (!this.eventDateList.length) {
      return []
    }

    const eventDates = [this.recurrence.startDate, ...this.eventDateList]
    const mappedEvents = eventDates.map((date) => {
      return {
        start: dayjs(date).tz(this.timeZone).format('YYYY-MM-DD'),
        end: dayjs(date).tz(this.timeZone).format('YYYY-MM-DD'),
        title: this.trip.routeName || `Trip ${1 + this.tripIdx}`,
      }
    })
    const filteredEvents = mappedEvents.filter((event) => {
      return !this.recurrence?.recurrenceExclusions?.find((exclusion) => {
        return dayjs(exclusion.exclusionDate).isSame(dayjs(event.start), 'day')
      })
    })

    return filteredEvents
  }

  get recurrence(): Recurrence {
    if (this.trip.recurrences?.[0]) {
      const recurrence = this.trip.recurrences[0]
      recurrence.endDate = dayjs(recurrence.endDate)
        .tz(this.timeZone)
        .format('YYYY-MM-DDTHH:mm:ss.000Z')
      return recurrence
    }
    const recurrence = new Recurrence()
    if (this.trip?.startDate && this.isRecurring) {
      recurrence.startDate = dayjs(this.trip?.startDate)
        .tz(this.timeZone)
        .toISOString()
    }
    return recurrence
  }

  get isConverted(): boolean {
    return !!this.state?.quote?.isConverted
  }

  recurrenceTripDelete(start: string): void {
    const startDate = dayjs(start)
    const tripStartTime = dayjs(this.trip.startDate)

    const exclusionDatetime = startDate
      .hour(tripStartTime.hour())
      .minute(tripStartTime.minute())
      .second(tripStartTime.second())
      .toISOString()

    const recurrence = deepClone(this.recurrence)
    if (!recurrence.recurrenceExclusions) {
      recurrence.recurrenceExclusions = []
    }
    recurrence.recurrenceExclusions.push({
      exclusionDate: exclusionDatetime,
    })
    const recurrences = [recurrence]
    this.state.updateTrip({
      tripIdx: this.tripIdx,
      trip: {
        recurrences,
        recurrenceTripCount: this.tripCount,
      },
    })
  }

  handleRecurrenceToggle(): void {
    if (this.isRecurring) {
      this.eventDateList = []
    }
    this.state.updateTrip({
      tripIdx: this.tripIdx,
      trip: {
        hasRecurrence: !this.isRecurring,
        recurrences: [],
        recurrenceTripCount: null,
      },
    })
  }

  async handleRecurrenceEndChange(value: string): Promise<void> {
    const endDate = value
      ? dayjs(value).tz(this.timeZone, true).toISOString()
      : ''

    await this.fetchRecurrenceDateList({ ...this.recurrence, endDate })

    this.state.updateTrip({
      tripIdx: this.tripIdx,
      trip: {
        recurrences: [{ ...this.recurrence, endDate }],
        recurrenceTripCount: this.tripCount,
      },
    })
  }

  async fetchRecurrenceDateList(recurrence: Recurrence): Promise<void> {
    if (!recurrence.endDate || !recurrence.recurrenceType) {
      return
    }
    const res = await quoteService.getRecurrence([recurrence])
    this.eventDateList = res.data.recurrenceEventDateList
  }

  async handleRecurrenceChange(recurrence: Recurrence): Promise<void> {
    recurrence.recurrenceExclusions = []
    await this.fetchRecurrenceDateList(recurrence)
    this.state.updateTrip({
      tripIdx: this.tripIdx,
      trip: {
        recurrences: [{ ...recurrence }],
        recurrenceTripCount: this.tripCount,
      },
    })
  }

  async mounted(): Promise<void> {
    if (this.trip.recurrences?.length) {
      const recurrence = this.trip.recurrences[0]
      const res = await quoteService.getRecurrence([recurrence])
      this.eventDateList = res.data.recurrenceEventDateList
    }
  }
}
