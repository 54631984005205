
import { Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { QuoteDetailsTab } from '@/models/dto/Tab'
import { PaymentMethod, PaymentMethodKeys, Trip } from '@/models/dto'
import {
  cleanQuoteForSave,
  getQuoteInformation,
  withAllPricingMethods,
} from '@/utils/quote'
import QuoteSidebarDetail from '@/components/QuoteSidebarDetail.vue'
import TripNotes from '@/components/TripNotes.vue'
import QuoteCustomerDetails from '@/components/QuoteCustomerDetails.vue'
import TripPricing from '@/components/TripPricing.vue'
import QuotePayment from '@/components/QuotePayment.vue'
import QuoteNotes from '@/components/QuoteNotes.vue'
import QuoteSummarySidebarDetailExpanded from '@/components/QuoteSummarySidebarDetailExpanded.vue'
import TripRecurrence from '@/components/TripRecurrence.vue'
import TripDetails from '@/components/TripDetails.vue'
import client from '@/services/quotes'
import quote from '@/store/modules/quote'
import sidebar from '@/store/modules/sidebar'
import typeState from '@/store/modules/types'
import { Quote } from '@/models/dto/Quote'
import { QuoteStatusIds, Status } from '@/models/dto/Status'
import { Priority } from '@/models/dto/Priority'
import { Action } from '@/models/dto/Action'
import { apiBaseUrl, baseUrl } from '@/utils/env'
import { QuoteInformation } from '@/models/QuoteInformation'
import QuoteDetailBeginConvert from '@/components/QuoteDetailBeginConvert.vue'
import QuoteDetailSendConfirmation from '@/components/QuoteDetailSendConfirmation.vue'
import { addDefaultChargesToTrip } from '@/utils/charge'
import ContentWithSidebar from '@/layouts/ContentWithSidebar.vue'
import CustomEmailSidebar from '@/components/CustomEmailSidebar.vue'
import customer from '@/services/customer'
import companyService from '@/services/company'
import auth from '@/store/modules/auth'
import { Customer } from '@/models/dto/Customer'
import { EmailTemplate } from '@/models/dto/Template'
import { Email } from '@/models/dto/Email'
import dayjs from 'dayjs'
import { Tag } from '@/models/dto/Tag'
import tagsClient from '@/services/tag'
import HoldUpModal from '@/components/HoldUpModal.vue'
import { EventBus } from '@/utils/eventBus'
import messages from '@/data/messages'
import { QuoteStatusTypeId, SourceCategory } from '@/utils/enum'
import CharterUpReferralTripDetail from '@/components/CharterUpReferralTripDetail.vue'
import { subject, body } from '@/data/quoteTemplate'
import { shouldFetchTripPricing, getRecurringTripTotal } from '@/utils/trip'
import { Note } from '@/models/dto/Note'
import currency from 'currency.js'
import { EventType } from '@/models/EventType'
import deepClone from '@/utils/deepClone'
import WidgetReferralTripDetail from '@/components/WidgetReferralTripDetail.vue'
import { parseAxiosError } from '@/utils/error'
import termsService from '@/services/terms'
import SendSoldOutSidebar from '@/components/SendSoldOutSidebar.vue'
import { Contact } from '@/models/Contact'
import app from '@/store/modules/app'
import { sumBy } from '@/utils/reducers'

const DUE_NOW_MODAL_SUFFIX =
  'amount with your Processing Fees included exceeds your online payment processing max transaction limit. Do you want to proceed?'

@Component({
  components: {
    ContentWithSidebar,
    QuoteSidebarDetail,
    QuoteCustomerDetails,
    QuoteDetailBeginConvert,
    QuoteDetailSendConfirmation,
    HoldUpModal,
  },
})
export default class QuoteDetails extends mixins(DateMixin) {
  state = quote

  isSaving = false
  tabDefaults = []
  contacts: Customer[] = []
  deleteIdx: number = null
  showDeleteModal = false
  showMaxTransactionWarningModal = false
  dueNowWarningModalText = ''
  tripEstimationDebounce = null
  tags: Tag[] = []
  showCustomerTabError = false
  tabIndex = null
  errors = {
    customerTab: false,
    paymentTab: false,
    tripTabs: [],
  }
  quoteActionCallbackFn: () => void = undefined

  get tripIds(): number[] {
    return this.trips.map(({ tripId }) => tripId)
  }

  @Watch('tripIds', { immediate: true })
  handleTripLengthChange(newTripIds: number[]): void {
    if (!newTripIds?.length) {
      return
    }

    // If a trip was removed, remove it from the error object
    const tripTabErrors = this.errors.tripTabs.filter(({ tripId }) =>
      newTripIds.includes(tripId)
    )

    const errorTripIds = tripTabErrors.map(({ tripId }) => tripId)

    // If a trip was added, add it to the error object
    for (const tripId of newTripIds) {
      if (!errorTripIds.includes(tripId)) {
        tripTabErrors.push({
          tripId,
          detailTab: false,
          pricingTab: false,
        })
      }
    }

    this.errors.tripTabs = tripTabErrors
  }
  quoteHasBeenModified = false

  openCheckoutPage(): void {
    const routeData = this.$router.resolve({
      name: 'checkout',
      params: { id: this.state.quote.hash },
    })
    window.open(routeData.href, '_blank')
  }

  openCheckoutPdf(): void {
    const url = `https://${apiBaseUrl('pdf')}/pdf/provider-quote-${
      quote.quote.hash
    }.pdf`
    window.open(url)
  }

  get actions(): Action[] {
    const availableActions = []
    if (!this.state?.quote?.isConverted) {
      availableActions.push(
        {
          label: 'Send to Customer',
          id: 'send-to-customer',
          event: 'quote:send-to-customer',
          icon: 'send',
        },
        {
          label: 'Convert to Reservation',
          event: 'quote:convert',
          icon: 'cached',
          iconLabel: 'Convert',
          id: 'convert-to-reservation',
        }
      )
    } else if (this.state.quote.quoteConversionDetailsId) {
      availableActions.push({
        label: 'Send Confirmation',
        id: 'send-confirmation',
        event: 'quote:send-confirmation',
        icon: 'send',
      })
    }
    availableActions.push(
      {
        label: 'View PDF',
        id: 'view-pdf',
        event: 'quote:view-pdf',
        icon: 'pdf',
      },
      {
        label: 'View Checkout',
        id: 'view-checkout',
        event: 'quote:view-checkout',
        icon: 'payment',
        iconLabel: 'Checkout',
      }
    )

    return availableActions
  }

  actionEvents = {
    'quote:send-to-customer': (): void =>
      this.validateAndSaveQuote(this.handleSendToCustomer),
    'quote:send-confirmation': this.handleSendConfirmation,
    'quote:view-checkout': (): void =>
      this.validateAndSaveQuote(this.openCheckoutPage),
    'quote:view-pdf': (): void =>
      this.validateAndSaveQuote(this.openCheckoutPdf),
    'quote:convert': (): void =>
      this.validateAndSaveQuote(this.handleConvertQuote),
  }

  get isCharterUpReferral(): boolean {
    return this.state.quote?.sourceCategory === SourceCategory.REFERRAL
  }

  get isWidgetReferral(): boolean {
    return this.state.quote?.sourceCategory === SourceCategory.WEBSITE
  }

  get firstTripIdx(): number {
    return this.tabs.findIndex((tab) => tab.isTrip)
  }

  get deleteName(): string {
    if (this.deleteIdx) {
      return (
        this.trips[this.deleteIdx].routeName || `Trip ${this.deleteIdx + 1}`
      )
    }
    return null
  }

  get title(): string {
    return this.state.quote?.managedId
      ? `Quote ${this.state.quote.managedId}`
      : 'New Quote'
  }

  get lastUpdated(): string {
    if (this.state.quote?.isConverted) {
      const ts = this.state.quote?.convertedOn
      return `Converted on ${this.formatMediumDateShortTime(ts, {
        tz: auth.getUserTimeZone,
        showMeridianUpper: true,
      })}`
    } else {
      const ts = this.state.quote?.updatedOn
      return `Last saved: ${
        ts
          ? this.formatMediumDateShortTime(ts, {
              tz: auth.getUserTimeZone,
              showMeridianUpper: true,
            })
          : 'never'
      }`
    }
  }

  get trips(): Trip[] {
    return this.state.trips
  }

  get addTripActions(): Action[] {
    return [
      { label: 'Add New Trip', event: 'select' },
      ...this.trips.map((trip, idx) => {
        return {
          label: `Copy ${trip.routeName || `Trip ${1 + idx}`}`,
          id: trip.tripId,
          event: 'select',
        }
      }),
    ]
  }

  get dueNowTotal(): number {
    return this.trips.reduce(sumBy('recurringAmountDueNow'), 0)
  }

  private tripTabTitleHelper = (
    idx: number,
    routeName: string,
    section: string
  ): string => {
    const quoteName = this.state.quote?.managedId
      ? `Quote ${this.state.quote.managedId}`
      : 'New Quote'
    const tripName = routeName || `Trip ${idx + 1}`
    return `${quoteName} - ${tripName} ${section}`
  }

  private mainTabTitleHelper = (section?: string): string => {
    if (!section) {
      return this.state.quote?.managedId
        ? `Quote ${this.state.quote.managedId}`
        : 'New Quote'
    }

    return this.state.quote?.managedId
      ? `Quote ${this.state.quote.managedId} - ${section}`
      : `New Quote - ${section}`
  }

  get tabs(): QuoteDetailsTab[] {
    return this.isCharterUpReferral
      ? [
          {
            label: 'Lead',
            component: CharterUpReferralTripDetail,
            hash: 'cup-referral-trip',
            id: 'cup-referral-trip',
            title: this.mainTabTitleHelper(),
            props: { quote: this.state.quote, isSelected: this.tabIndex === 0 },
            on: { reject: () => this.loadQuote(this.state.quote.quoteId) },
          },
        ]
      : [
          ...(this.isWidgetReferral
            ? [
                {
                  label: 'Lead',
                  component: WidgetReferralTripDetail,
                  hash: 'widget-referral-trip',
                  id: 'widget-referral-trip',
                  title: this.mainTabTitleHelper(),
                  props: {
                    quote: this.state.quote,
                    isSelected: this.tabIndex === 0,
                  },
                },
              ]
            : []),
          {
            label: 'Customer',
            component: QuoteCustomerDetails,
            props: {},
            ref: 'quote-customer-details',
            id: 'quote-customer-details',
            hash: 'customer-details',
            title: this.mainTabTitleHelper('Customer'),
            error: this.errors.customerTab,
            eager: true,
            on: {
              'quote-customer-details:reset-errors': () => {
                this.errors.customerTab = false
              },
            },
          },
          ...this.trips.map((trip, tripIdx) => {
            const tripEstimation = this.state.estimations[trip.tripId]
            const tripPricings = this.state.getTripPricings(tripIdx)
            const errors = this.errors.tripTabs.find(
              ({ tripId }) => tripId === trip.tripId
            )

            return {
              label: trip.routeName || `Trip ${1 + tripIdx}`,
              removable:
                !this.state.quote?.isConverted && this.trips.length > 1,
              editable: !this.state.quote?.isConverted,
              eager: true,
              subtabHashes: [
                `trip-${tripIdx}-details`,
                `trip-${tripIdx}-pricing`,
                `trip-${tripIdx}-notes`,
                `trip-${tripIdx}-recurrence`,
              ],
              title: this.tripTabTitleHelper(
                tripIdx,
                trip.routeName,
                'Details'
              ),
              error: errors.detailTab || errors.pricingTab,
              isTrip: true,
              ref: `quote-trip-tab-${tripIdx}`,
              id: `quote-trip-tab-${tripIdx}`,
              subtabs: [
                {
                  label: 'Details',
                  component: TripDetails,
                  props: { trip, tripEstimation },
                  hash: `trip-${tripIdx}-details`,
                  id: `trip-details-${trip.tripId.toString()}`,
                  title: this.tripTabTitleHelper(
                    tripIdx,
                    trip.routeName,
                    'Details'
                  ),
                  on: {
                    input: (trip: Trip) =>
                      this.handleTripUpdate({ trip, tripIdx }),
                    'input-silent': (trip: Trip) =>
                      this.handleTripUpdate({ trip, tripIdx, silent: true }),
                    'trip-details:reset-errors': () => {
                      const tripTab = this.errors.tripTabs.find(
                        ({ tripId }) => tripId === trip.tripId
                      )
                      tripTab.detailTab = false
                    },
                  },
                  eager: true,
                  ref: `quote-trip-tab-${trip.tripId}-detail`,
                  error: errors.detailTab,
                },
                {
                  label: 'Pricing',
                  component: TripPricing,
                  hash: `trip-${tripIdx}-pricing`,
                  id: `trip-pricing-${trip.tripId.toString()}`,
                  props: {
                    trip,
                    tripIdx,
                    tripEstimation,
                    tripPricings,
                    readOnly: this.state?.quote?.isConverted,
                  },
                  title: this.tripTabTitleHelper(
                    tripIdx,
                    trip.routeName,
                    'Pricing'
                  ),
                  on: { input: this.handleTripUpdate },
                  eager: true,
                },
                {
                  label: 'Notes',
                  component: TripNotes,
                  hash: `trip-${tripIdx}-notes`,
                  id: `trip-notes-${trip.tripId.toString()}`,
                  props: { tripIdx, isQuote: true },
                  title: this.tripTabTitleHelper(
                    tripIdx,
                    trip.routeName,
                    'Notes'
                  ),
                },
                {
                  label: 'Recurrence',
                  hash: `trip-${tripIdx}-recurrence`,
                  id: `trip-recurrence-${trip.tripId.toString()}`,
                  component: TripRecurrence,
                  props: { tripIdx },
                  title: this.tripTabTitleHelper(
                    tripIdx,
                    trip.routeName,
                    'Recurrence'
                  ),
                },
              ],
            }
          }),
          {
            label: 'Payment',
            component: QuotePayment,
            ref: 'quote-payment-details',
            id: 'quote-payment-details',
            hash: 'payment-details',
            error: this.errors.paymentTab,
            eager: true,
            on: {
              input: this.state.updateQuote,
              'quote-payment:reset-errors': () => {
                this.errors.paymentTab = false
              },
            },
            title: this.mainTabTitleHelper('Payment'),
          },
          {
            label: 'Notes',
            component: QuoteNotes,
            ref: 'quote-detail-notes',
            id: 'quote-detail-notes',
            hash: 'notes',
            title: this.mainTabTitleHelper('Notes'),
            eager: true,
            props: {
              quoteNote: this.state?.quote?.quoteNote,
              disabled: this.state?.quote?.isConverted,
            },
            on: {
              'quote:update-note': (note: Note) => {
                this.state.updateQuote({ quoteNote: note })
              },
            },
          },
        ]
  }

  rerouteToQuote(quoteId: number): number {
    const name = 'quotes.detail'
    const params = { id: String(quoteId) }
    this.$router.push({ name, params })
    return quoteId
  }

  async handleAddTrip({
    label,
    id,
    event,
  }: {
    label: string
    id: number
    event: string
  }): Promise<void> {
    let tripDuplicate = this.trips.find((trip) => trip.tripId === id)

    const company = auth.getCompany

    let depositPercentage
    let dueDate

    if (company.defaultPercentageDueNow) {
      depositPercentage = Number(company.defaultPercentageDueNow)
    } else {
      depositPercentage = 0
    }

    if (company.defaultDueDaysFromDate && tripDuplicate) {
      if (company.defaultDueBeforePickup) {
        dueDate = dayjs(tripDuplicate.startDate)
          .subtract(company.defaultDueDaysFromDate, 'day')
          .toISOString()
      } else {
        dueDate = dayjs(tripDuplicate.startDate)
          .add(company.defaultDueDaysFromDate, 'day')
          .toISOString()
      }
    }

    if (tripDuplicate) {
      tripDuplicate = deepClone(tripDuplicate)
      if (tripDuplicate?.garageTimes) {
        tripDuplicate.garageTimes.tripId = null
      }

      const tripNotes =
        tripDuplicate.tripNotes?.map((n) => ({
          ...n,
          createdOn: null,
          noteId: null,
        })) || []

      const recurrences =
        tripDuplicate.recurrences?.map((r) => ({
          ...r,
          recurrenceId: null,
          tripId: null,
        })) || []

      this.state.addTrip({
        ...tripDuplicate,
        lineItemCharges: tripDuplicate.lineItemCharges.map((c) => {
          return { ...c, tripId: null, id: null }
        }),
        tripNotes,
        recurrences,
        tripId: this.state.trips.length,
      })
      this.handleUpdateTripEstimation()
      this.handleUpdateTripPricing()
    } else {
      const trip = await addDefaultChargesToTrip()
      const depositAmount =
        currency(trip.recurringTripTotal)
          .multiply(depositPercentage)
          .divide(100).value || 0
      this.state.addTrip({
        ...trip,
        tripId: this.state.trips.length,
        depositPercentage,
        depositAmount,
        recurringAmountDueNow: depositAmount,
        dueDate,
      })
    }
  }

  handleEditTab(val: { idx: number; name: string }): void {
    const trip: Partial<Trip> = { routeName: val.name }
    this.state.updateTrip({ tripIdx: val.idx - this.firstTripIdx, trip })
  }

  handleTripUpdate({
    trip,
    tripIdx,
    silent = false,
  }: {
    trip: Trip
    tripIdx: number
    silent?: boolean
  }): void {
    if (
      !this.state.trips[tripIdx].startDate &&
      trip.startDate &&
      auth.getCompany.defaultDueDaysFromDate
    ) {
      if (auth.getCompany.defaultDueBeforePickup) {
        trip.dueDate = dayjs(trip.startDate)
          .subtract(auth.getCompany.defaultDueDaysFromDate, 'day')
          .toISOString()
      } else {
        trip.dueDate = dayjs(trip.startDate)
          .add(auth.getCompany.defaultDueDaysFromDate, 'day')
          .toISOString()
      }
    }

    this.state.updateTrip({ trip, tripIdx, silent })

    if (this.tripEstimationDebounce) {
      window.clearTimeout(this.tripEstimationDebounce)
    }
    this.tripEstimationDebounce = window.setTimeout(async () => {
      const updatedTrip = Object.assign({}, this.state.trips[tripIdx], trip)
      await this.handleUpdateTripEstimation()
      if (shouldFetchTripPricing(updatedTrip)) {
        await this.handleUpdateTripPricing()
      }
    }, 250)
  }

  async handleUpdateTripEstimation(): Promise<void> {
    try {
      const res = await client.tripEstimations(this.state.quote)
      this.state.setTripEstimations(res.data)
    } catch (e) {
      return
      // Intentionally blank, catching the tripEstimations endpoint
      // returning a 500
    }
  }

  async handleUpdateTripPricing(): Promise<void> {
    try {
      const res = await client.pricing(withAllPricingMethods(this.state.quote))
      const pricing = res.data
      this.state.setPricing(pricing)
    } catch (e) {
      return
    }
  }

  async handleUpdateDefaultTerms(): Promise<void> {
    try {
      // Load default terms
      const { data: terms } = await termsService.tableView({
        page: 1,
        pageSize: -1,
      })
      const defaultTerms = terms.resultList?.find((t) => !!t.isDefault)
      if (defaultTerms && !this.state.quote?.termsId) {
        this.state.updateQuote({ termsId: defaultTerms.companyTermsId })
        this.state.setModified(false)
      }
    } catch (e) {
      return
    }
  }

  handleSetDeleteIdx(idx: number[]): void {
    if (idx?.length === 1 && idx[0] >= 1) {
      this.deleteIdx = idx[0] - this.firstTripIdx
      this.showDeleteModal = true
    }
  }

  handleDeleteTab(): void {
    const tripIdx = this.deleteIdx
    this.deleteIdx = null
    this.showDeleteModal = false
    this.state.deleteTrip({ tripIdx })
  }

  handleQuoteStatusChange(quoteStatus: Status): void {
    if (
      quoteStatus?.id === QuoteStatusIds.SOLD_OUT &&
      app.isSoldOutSidebarEnabled
    ) {
      const booking = this.contacts.find(
        (c) => c.customerId === quote.quote.customerId
      )
      const billing = this.contacts.find(
        (c) => c.customerId === quote.quote.billingCustomerId
      )
      const contacts = [
        ...(booking ? [{ ...booking, type: 'Booking' }] : []),
        ...(billing ? [{ ...billing, type: 'Billing' }] : []),
      ]
      sidebar.push({
        title: 'Mark as Sold Out',
        component: SendSoldOutSidebar,
        width: 402,
        props: { contacts },
        on: {
          cancel: () => sidebar.pop(),
          notify: this.handleSendSoldOutEmails,
        },
      })
      return
    }

    const quoteStatusId = quoteStatus?.id || null
    this.state.updateQuote({ quoteStatusId, quoteStatus })
    this.saveQuote()
  }

  handleQuotePriorityChange(quotePriority: Priority): void {
    const quotePriorityId = quotePriority?.id || null
    this.state.updateQuote({ quotePriorityId, quotePriority })
    this.saveQuote()
  }

  handleQuoteEventTypeChange(eventType: EventType): void {
    const eventTypeId = eventType?.id || null
    this.state.updateQuote({ eventTypeId })
    this.saveQuote()
  }

  handleReferredByChange(referredBy: string): void {
    this.state.updateQuote({ referredBy })
    this.saveQuote()
  }

  handleManagedIdChange(managedId: string): void {
    this.state.updateQuote({ managedId })
    this.saveQuote()
  }

  handleTagChange(tags: Tag[]): void {
    this.tags = tags
  }

  async handleSendSoldOutEmails(contacts: Contact[]): Promise<void> {
    this.isSaving = true
    try {
      const res = await client.updateQuoteStatuses(
        [this.state.quote.quoteId],
        QuoteStatusIds.SOLD_OUT,
        contacts
      )
      if (res.status === 200) {
        EventBus.$emit('snackbar:success', 'Successfully marked as Sold Out')
        this.state.updateQuote({
          quoteStatusId: QuoteStatusIds.SOLD_OUT,
          quoteStatus: {
            key: 'sold_out',
            label: 'Sold Out',
            id: QuoteStatusIds.SOLD_OUT,
          },
        })
      } else {
        EventBus.$emit('snackbar:error', 'Failed to send email')
      }
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', 'Failed to send email')
    } finally {
      this.isSaving = false
    }
  }

  validateAndSaveQuote(callbackFn: () => void = undefined): void {
    this.quoteActionCallbackFn = callbackFn

    let isValid
    if (callbackFn) {
      isValid = this.validate({ validateForQuoteAction: true })
    } else {
      isValid = this.validate()
    }

    if (!isValid) {
      return
    }
    const isDueNowValid = this.validate({ validateForMaxDueNow: true })
    if (!isDueNowValid) {
      this.showMaxTransactionWarningModal = true
      return
    }
    this.reset()

    const isSaved = this.saveQuote()
    if (isSaved) {
      this.executeQuoteActionCallback()
    }
  }

  async saveQuote(): Promise<boolean> {
    const quote = cleanQuoteForSave(this.state.quote)
    if (quote) {
      this.isSaving = true
      if (!quote.quoteId) {
        const { data } = await client.create(quote)
        const {
          quote: { quoteId },
        } = data
        await this.rerouteToQuote(quoteId)
        const quoteInformation = await this.loadQuote(quoteId)
        await this.saveQuoteTags(quoteInformation)
        this.isSaving = false
        this.quoteHasBeenModified = false
        return true
      } else {
        try {
          const { data } = await client.update(quote)
          const {
            quote: { quoteId },
          } = data
          await this.loadQuote(quoteId)
        } catch (err: any) {
          if (err.response?.data?.message?.includes('already converted')) {
            EventBus.$emit(
              'snackbar:error',
              'This quote has been converted, please try again'
            )
            this.loadQuote(this.state.quote.quoteId)
          } else {
            EventBus.$emit('snackbar:error', 'Failed to update quote')
          }
          return false
        } finally {
          this.isSaving = false
          this.quoteHasBeenModified = false
        }
        return true
      }
    }
  }

  executeQuoteActionCallback(): void {
    if (this.quoteActionCallbackFn) {
      const isActionValid = this.validate({ validateForQuoteAction: true })
      if (isActionValid) {
        this.quoteActionCallbackFn()
      }
    }
  }

  async loadQuote(quoteId: number): Promise<QuoteInformation> {
    const quoteInfo = await getQuoteInformation(quoteId)
    this.state.setQuoteInformation(quoteInfo)
    return quoteInfo
  }

  reset(): void {
    const tabs: any = this.$refs['tabs']

    const quoteCustomerDetails: any = tabs.$refs['quote-customer-details']?.[0]
    if (quoteCustomerDetails) {
      quoteCustomerDetails.reset()
    }

    const quotePaymentDetails: any = tabs.$refs['quote-payment-details']?.[0]
    if (quotePaymentDetails) {
      quotePaymentDetails.reset()
    }

    for (const tripId of this.tripIds) {
      const tripTab =
        tabs.$refs[`quote-trip-tab-${tripId}`]?.[0]?.$refs[
          `quote-trip-tab-${tripId}-detail`
        ]?.[0]
      // if (tripTab) {
      //   tripTab.reset()
      // }
    }
  }

  validate(
    options: {
      validateForQuoteAction?: boolean
      validateForMaxDueNow?: boolean
    } = {
      validateForQuoteAction: false,
      validateForMaxDueNow: false,
    }
  ): boolean {
    const tabs: any = this.$refs['tabs']
    const quoteCustomerDetails: any = tabs.$refs['quote-customer-details']
    const quotePaymentDetails: any = tabs.$refs['quote-payment-details']
    const tripTabs = this.tripIds.map((tripId, i) => {
      const tripTab = tabs.$refs[`quote-trip-tab-${i}`]?.[0]
      const tab = tripTab?.$refs[`quote-trip-tab-${tripId}-detail`]?.[0]
      return { tripId, tab }
    })

    const cardPaymentMethod = this.state.quote.paymentMethods.find(
      (p) =>
        p.paymentMethodType.key === PaymentMethodKeys.CREDIT_CARD && p.isActive
    )

    const achPaymentMethod = this.state.quote.paymentMethods.find(
      (p) => p.paymentMethodType.key === PaymentMethodKeys.ACH && p.isActive
    )

    const isCardActive = !!cardPaymentMethod
    const isAchActive = !!achPaymentMethod
    const isPayInFullActive = this.state.quote.enablePayFullAmountNow
    const cardProcessingFee = (cardPaymentMethod?.processingFee || 0) / 100
    const achProcessingFee = (achPaymentMethod?.processingFee || 0) / 100

    let formIsValid = true
    let isMissingInformation = false
    let dateTimesAreInvalid = false
    let missingTimes = false

    if (options.validateForMaxDueNow) {
      const cardTotal = currency(this.dueNowTotal).multiply(
        1 + cardProcessingFee
      ).value
      const achTotal = currency(this.dueNowTotal).multiply(
        1 + achProcessingFee
      ).value
      const fullCardTotal = isPayInFullActive
        ? currency(this.state.quote.amount).multiply(1 + cardProcessingFee)
            .value
        : -1
      const fullAchTotal = isPayInFullActive
        ? currency(this.state.quote.amount).multiply(1 + achProcessingFee).value
        : -1

      if (
        (isCardActive && cardTotal > auth.maxCardTransactionAmount) ||
        (isAchActive && achTotal > auth.maxAchTransactionAmount)
      ) {
        this.dueNowWarningModalText = `The Due Now ${DUE_NOW_MODAL_SUFFIX}`
        return false
      } else if (
        (isCardActive && fullCardTotal > auth.maxCardTransactionAmount) ||
        (isAchActive && fullAchTotal > auth.maxAchTransactionAmount)
      ) {
        this.dueNowWarningModalText = `The Pay In Full ${DUE_NOW_MODAL_SUFFIX}`
        return false
      }

      return true
    }

    if (quoteCustomerDetails?.[0] && !quoteCustomerDetails[0].validate()) {
      this.errors.customerTab = true
      isMissingInformation = true
      formIsValid = false
    }

    for (const trip of tripTabs) {
      if (trip.tab) {
        const errorWithTimes = !trip.tab.validateStopsAndGarageTimes()
        const errorWithAddresses =
          trip.tab.validateAllStopsWithDateHaveAddress()
        if (errorWithTimes || errorWithAddresses) {
          formIsValid = false
          const tripTab = this.errors.tripTabs.find(
            (tab) => tab.tripId === trip.tripId
          )
          tripTab.detailTab = true
          isMissingInformation = errorWithAddresses || isMissingInformation
          missingTimes = errorWithTimes
          dateTimesAreInvalid = errorWithTimes || dateTimesAreInvalid
        }
      }
    }

    if (!options.validateForQuoteAction) {
      let message = ''
      if ((isMissingInformation && !dateTimesAreInvalid) || missingTimes) {
        message =
          'You are missing some required information, please review and try again.'
      } else if (isMissingInformation && dateTimesAreInvalid) {
        message =
          'You are missing some required information, and some of your garages and/or stop times are out of order. Please review and try again.'
      } else if (dateTimesAreInvalid) {
        message =
          'Some of your garages and/or stop times are out of order. Please review and try again.'
      }

      if (!formIsValid) {
        EventBus.$emit('snackbar:error', message)
      }
      return formIsValid
    }

    if (quotePaymentDetails?.[0] && !quotePaymentDetails[0].validate()) {
      this.errors.paymentTab = true
      formIsValid = false
    }

    for (const trip of tripTabs) {
      if (trip.tab) {
        const errorWithTripAddresses = trip.tab.validateAllStopsHaveAddress()
        const tripInfoIsValid = trip.tab.validate(true)

        if (errorWithTripAddresses || !tripInfoIsValid) {
          const tripTab = this.errors.tripTabs.find(
            (tab) => tab.tripId === trip.tripId
          )
          tripTab.detailTab = true
          formIsValid = false
        }
      }
    }

    if (!formIsValid) {
      EventBus.$emit(
        'snackbar:error',
        'You are missing some required information, please review and try again.'
      )
    }

    return formIsValid
  }

  saveQuoteTags(quote: QuoteInformation): void {
    if (this.tags.length) {
      tagsClient.addQuoteTags(
        this.tags.map((t) => t.tagId),
        quote.quote.quoteId
      )
    }
  }

  addQuoteSummaryAsFABComponent(): void {
    sidebar.addFabComponent({
      title: '',
      component: QuoteSummarySidebarDetailExpanded,
      props: {
        actions: this.actions,
        actionEvents: this.actionEvents,
      },
      width: 1479,
      fab: true,
      wide: false,
      overlayBackground: true,
    })
  }

  handleConfirmMaxTransactionWarning(): void {
    const isSaved = this.saveQuote()
    if (isSaved) {
      this.executeQuoteActionCallback()
    }
  }

  handleConvertQuote(): void {
    sidebar.push({
      component: QuoteDetailBeginConvert,
      title: 'Convert to Reservation',
    })
  }

  async handleSendToCustomer(): Promise<void> {
    const bookingContact = this.contacts.find(
      (c) => c.customerId === quote.quote.customerId
    )
    const billingContact = this.contacts.find(
      (c) => c.customerId === quote.quote.billingCustomerId
    )

    const defaultTemplate = new EmailTemplate({
      subject: subject.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      opener: body.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      includePdf: true,
    })
    const quoteTemplatesResult = await client.getEmailTemplate()
    const quoteTemplates = quoteTemplatesResult.data?.emailCustomizationDTO
      ? [quoteTemplatesResult.data.emailCustomizationDTO, defaultTemplate]
      : [defaultTemplate]

    sidebar.push({
      component: CustomEmailSidebar,
      props: {
        id: quote.quote.managedId,
        link: `https://${baseUrl()}/checkout/${quote.quote.hash}`,
        pdfType: 'Quote',
        isQuote: true,
        title: `Quote ${quote.quote.managedId}`,
        customers: this.contacts,
        templates: quoteTemplates,
        useAlternateSender: true,
        bookingContact,
        billingContact,
      },
      width: 500,
      on: { send: (email: Email) => this.sendEmail(email) },
    })
  }

  handleSendConfirmation(): void {
    sidebar.push({
      component: QuoteDetailSendConfirmation,
      title: 'Send Booking Confirmation',
    })
  }

  async sendEmail(email: Email): Promise<void> {
    try {
      await client.sendQuoteEmail({
        timezone: auth.getUserTimeZone,
        quoteId: quote.quote.quoteId,
        ...email,
      })
      this.loadQuote(quote.quote.quoteId)
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  async getDuplicateQuoteData(quoteId: number): Promise<Quote> {
    const quoteRes = await client.byId(quoteId)
    const quote = quoteRes.data.quote

    const tagRes = await tagsClient.byQuoteId(quoteId)
    this.tags = tagRes.data?.tags

    quote.quoteId = null
    quote.hash = null
    quote.convertedOn = null
    quote.isConverted = false
    quote.updatedOn = null
    quote.expirationDate = null
    quote.createdOn = null
    quote.createdBy = null
    quote.createdById = null
    quote.modifiedById = null
    quote.managedId = null
    quote.reservationIds = null
    quote.quoteStatus = null
    quote.quoteStatusId = QuoteStatusTypeId.Lead
    quote.paymentMethods.map((m) => {
      delete m.id
      delete m.quoteId
    })
    quote.trips.map((t, tripIdx) => {
      t.tripId = tripIdx
      t.createdOn = null
      if (t.garageTimes) {
        delete t.garageTimes.tripId
      }
      t.charges.map((c) => {
        delete c.tripId
        delete c.tripChargeId
      })
      t.lineItemCharges.map((c) => {
        delete c.id
        delete c.tripId
      })
      t.recurrences.map((t) => {
        delete t.recurrenceId
      })
      t.rates.map((r) => {
        delete r.tripId
        delete r.tripRateId
      })
      t.stops
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((s) => {
          delete s.stopId
          delete s.tripId
          s.createdOn = null
          if (s.spotTime) {
            delete s.spotTime.tripId
            delete s.spotTime.stopId
          }
          const tz = s?.address?.timeZone || auth.getUserTimeZone
          if (s.pickupDatetime) {
            s.pickupTime = dayjs(s.pickupDatetime).tz(tz).format('HH:mm')
            s.pickupDate = dayjs(s.pickupDatetime).tz(tz).format('YYYY-MM-DD')
          }
          if (s.dropoffDatetime) {
            s.dropoffTime = dayjs(s.dropoffDatetime).tz(tz).format('HH:mm')
            s.dropoffDate = dayjs(s.dropoffDatetime).tz(tz).format('YYYY-MM-DD')
          }
        })
      t.vehicles.map((v) => {
        delete v.tripId
        delete v.tripVehicleId
      })
    })
    return quote
  }

  async loadContacts(): Promise<Customer[]> {
    const res = await customer.tableView({ pageSize: -1 })
    return res.data.resultList
  }

  async loadQuoteDefaults(): Promise<void> {
    const { data } = await companyService.byId(auth.getCompanyId)
    const { company } = data
    await this.handleUpdateDefaultTerms()

    if (company.defaultPaymentPolicy) {
      this.state.updateQuote({ paymentPolicy: company.defaultPaymentPolicy })
    }

    if (company.defaultEnablePayFullAmountNow) {
      this.state.updateQuote({ enablePayFullAmountNow: true })
    }

    if (company.defaultRequireSignatureUponCheckout) {
      this.state.updateQuote({ requireSignatureUponCheckout: true })
    }

    if (company.defaultEnablePayLater) {
      this.state.updateQuote({ enablePayLater: true })
    }

    if (company.defaultEnablePONumber) {
      this.state.updateQuote({ enablePONumber: true })
    }

    this.state.updateQuote({
      enableExpiration: !!company.defaultEnableExpiration,
      expireAfterFirstSend: !!company.defaultExpireAfterFirstSend,
    })

    if (company.defaultExpirationDate) {
      this.state.updateQuote({
        expirationOffset: company.defaultExpirationDate,
      })
    }

    if (company.defaultExpirationType) {
      this.state.updateQuote({ expirationType: company.defaultExpirationType })
    }

    if (company.defaultCompanyPaymentMethods?.length) {
      const paymentMethods: PaymentMethod[] =
        company.defaultCompanyPaymentMethods.map((companyPaymentMethod) => {
          const paymentMethod: PaymentMethod = {
            paymentMethodTypeId: companyPaymentMethod.paymentMethodTypeId,
            paymentMethodType: companyPaymentMethod.paymentMethodType,
            processingFee: companyPaymentMethod.processingFee,
            note: companyPaymentMethod.note,
            isActive: companyPaymentMethod.isActive,
          }
          return paymentMethod
        })

      this.state.updateQuote({
        paymentMethods,
      })
    }

    for (const [tripIndex, trip] of Object.entries(this.state.trips)) {
      const tripIdx = Number(tripIndex)

      if (company.defaultGarageId) {
        const garage = typeState.garages.find(
          (g) => g.garageId === company.defaultGarageId
        )
        trip.garageTimes = Object.assign({}, trip.garageTimes, {
          garageId: company.defaultGarageId,
          garage,
          garageTimeNotes: garage.defaultGarageNotes,
          returnGarageId: company.defaultGarageId,
          returnGarage: garage,
          returnGarageTimeNotes: garage.defaultGarageNotes,
        })
      }

      if (company.defaultPercentageDueNow) {
        trip.depositPercentage = Number(company.defaultPercentageDueNow)
        trip.depositAmount = currency(Number(company.defaultPercentageDueNow))
          .multiply(trip.total)
          .divide(100).value
        trip.recurringAmountDueNow = trip.depositAmount
      }

      if (company.defaultDueDaysFromDate && trip.startDate) {
        if (company.defaultDueBeforePickup) {
          trip.dueDate = dayjs(trip.startDate)
            .subtract(company.defaultDueDaysFromDate, 'day')
            .toISOString()
        } else {
          trip.dueDate = dayjs(trip.startDate)
            .add(company.defaultDueDaysFromDate, 'day')
            .toISOString()
        }
      }

      if (company.defaultPricingSelectionId) {
        trip.pricingSelectionId = company.defaultPricingSelectionId
      }

      const defaultOverageRateTypeId = company.defaultOverageRateTypeId
      const defaultOverageRateType = company.defaultOverageRateType
      const defaultOverageRateAmount = company.defaultOverageRateAmount

      if (defaultOverageRateTypeId && defaultOverageRateAmount) {
        trip.rates.push({
          tripId: null,
          tripRateId: null,
          rateTypeId: defaultOverageRateTypeId,
          rateType: defaultOverageRateType,
          amount: defaultOverageRateAmount,
        })
      }

      this.state.updateTrip({ tripIdx, trip })
    }
  }

  async refreshContacts(): Promise<void> {
    try {
      this.contacts = await this.loadContacts()
    } catch (e) {
      console.error(e)
    }
  }

  beforeMount(): void {
    this.state.clear()
  }

  async mounted(): Promise<void> {
    const id = this.$route.params?.id
    const data = this.$route.params?.data
    const quoteId = Number.parseInt(id, 10)
    // Loading a preexisting quote
    if (!isNaN(quoteId)) {
      await this.loadQuote(quoteId)
      await this.handleUpdateDefaultTerms()
      this.state.loadTripEstimations()
      this.tabDefaults = [0]
    } else if (data) {
      // Loading a duplicate quote
      const duplicateQuote = await this.getDuplicateQuoteData(Number(data))
      this.state.setQuote(new Quote({ ...duplicateQuote }))
      this.handleUpdateTripEstimation()
      this.handleUpdateTripPricing()
      await this.handleUpdateDefaultTerms()
      this.state.setModified(true)
    } else {
      // Creating a new quote
      const trip = await addDefaultChargesToTrip()
      this.state.setQuote(new Quote({ trips: [trip] }))
      this.tabDefaults = [0]
      await this.loadQuoteDefaults()
      this.state.setModified(false)
    }
    this.contacts = await this.loadContacts()
    if (!this.isCharterUpReferral) {
      this.addQuoteSummaryAsFABComponent()
    }
    this.quoteHasBeenModified = false
    EventBus.$on('contact-sidebar:update-quote-contacts', this.refreshContacts)
  }

  beforeDestroy(): void {
    EventBus.$off('contact-sidebar:update-quote-contacts', this.refreshContacts)
  }

  destroyed(): void {
    sidebar.pop()
    sidebar.addFabComponent(null)
    this.state.clear()
  }
}
