
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Days } from '@/utils/enum'
import { Recurrence, RecurrenceType } from '@/models/dto/Recurrence'
import { toDays } from '@/utils/recurrence'
import CUDayPicker from '@/components/CUDayPicker.vue'
import tclient from '@/services/type'

@Component({
  components: { CUDayPicker },
})
export default class CURecurrencePicker extends Vue {
  @Prop({ required: true }) readonly value!: Recurrence
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  recurrenceTypes: RecurrenceType[] = []

  get days(): Days[] {
    return toDays(this.value)
  }

  get isCustom(): boolean {
    return this.value.recurrenceType?.label === 'Custom'
  }

  get recurrenceType(): RecurrenceType {
    return this.value?.recurrenceType
  }

  recurrenceTypeToDays: Record<string, Record<string, boolean>> = {
    daily: {
      repeatOnSunday: true,
      repeatOnMonday: true,
      repeatOnTuesday: true,
      repeatOnWednesday: true,
      repeatOnThursday: true,
      repeatOnFriday: true,
      repeatOnSaturday: true,
    },
    every_weekday: {
      repeatOnSunday: false,
      repeatOnMonday: true,
      repeatOnTuesday: true,
      repeatOnWednesday: true,
      repeatOnThursday: true,
      repeatOnFriday: true,
      repeatOnSaturday: false,
    },
  }

  handleRecurrenceTypeChange(recurrenceType: RecurrenceType): void {
    const recurrenceTypeId = recurrenceType.recurrenceTypeId
    const weekdayValues = this.recurrenceTypeToDays[recurrenceType.key] || {}
    this.$emit('input', {
      ...this.value,
      recurrenceType,
      recurrenceTypeId,
      ...weekdayValues,
    })
  }

  handleUpdateDays(days: Days[]): void {
    this.$emit('input', {
      ...this.value,
      repeatOnSunday: days.includes(Days.SUNDAY),
      repeatOnMonday: days.includes(Days.MONDAY),
      repeatOnTuesday: days.includes(Days.TUESDAY),
      repeatOnWednesday: days.includes(Days.WEDNESDAY),
      repeatOnThursday: days.includes(Days.THURSDAY),
      repeatOnFriday: days.includes(Days.FRIDAY),
      repeatOnSaturday: days.includes(Days.SATURDAY),
    })
  }

  mounted(): void {
    tclient
      .recurrenceTypes()
      .then((res) => res.data)
      .then(({ recurrenceTypes }) => (this.recurrenceTypes = recurrenceTypes))
  }
}
