import { ApiResult } from './ApiResult'

export interface Recurrence {
  recurrenceId: number
  tripId: number
  startDate: string
  endDate: string
  recurrenceTypeId: number
  repeatEveryCount: number
  repeatEveryUnitId: number
  repeatOnMonday: boolean
  repeatOnTuesday: boolean
  repeatOnWednesday: boolean
  repeatOnThursday: boolean
  repeatOnFriday: boolean
  repeatOnSaturday: boolean
  repeatOnSunday: boolean
  recurrenceType: RecurrenceType
  repeatEveryUnit: RepeatEveryUnit
  recurrenceExclusions: RecurrenceExclusion[]
}

// eslint-disable-next-line no-redeclare
export class Recurrence implements Recurrence {
  constructor(recurrence?: Partial<Recurrence>) {
    this.startDate = ''
    this.endDate = ''
    this.recurrenceExclusions = []
    this.repeatOnMonday = false
    this.repeatOnTuesday = false
    this.repeatOnWednesday = false
    this.repeatOnThursday = false
    this.repeatOnFriday = false
    this.repeatOnSaturday = false
    this.repeatOnSunday = false
    Object.assign(this, recurrence)
  }
}

export interface RecurrenceType {
  recurrenceTypeId: number
  key: string
  label: string
  description: string
}

export interface RepeatEveryUnit {
  repeatEveryUnitId: number
  key: string
  label: string
  description: string
}

export interface RecurrenceExclusion {
  recurrenceExclusionId?: number
  recurrenceId?: number
  exclusionDate: string
}

export interface RecurrenceEventListResponse extends ApiResult {
  recurrenceEventDateList: string[]
}
