
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Note } from '@/models/dto/Note'
import { NoteTypes } from '@/utils/enum'

@Component({})
export default class QuoteNotes extends Vue {
  @Prop({ required: false }) readonly quoteNote!: Note
  @Prop({ required: false, default: false }) readonly disabled!: boolean

  @Watch('quoteNote', { immediate: true })
  quoteNoteChange(): void {
    this.note = this.quoteNote
  }

  note: Note = new Note()

  get noteText(): string {
    return this.note?.html || this.note?.note
  }

  setNote(text: string, isHTML = false): void {
    const note: Note = this.note
    let updatedNote: Note

    if (this.quoteNote) {
      updatedNote = isHTML ? { ...note, html: text } : { ...note, note: text }
    } else {
      updatedNote = {
        noteId: null,
        noteType: NoteTypes.INTERNAL,
        note: !isHTML ? text : null,
        html: isHTML ? text : null,
        createdOn: null,
        createdBy: null,
        active: null,
      }
    }
    this.note = updatedNote
    this.$emit('quote:update-note', this.note)
  }
}
