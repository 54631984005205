
import { Vue, Component, Watch } from 'vue-property-decorator'
import contactClient from '@/services/customer'
import customerAccountClient from '@/services/customerAccount'
import { formatFullName } from '@/utils/string'
import CustomerContactSearch from '@/components/CustomerContactSearch.vue'
import { Action } from '@/models/dto/Action'
import {
  ContactRequest,
  ContactTypeKey,
  SimpleContact,
} from '@/models/dto/Customer'
import sidebar from '@/store/modules/sidebar'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import _cloneDeep from 'lodash.clonedeep'
import DetailsContent from '@/components/DetailsContent.vue'
import CUContactCard from '@/components/CUContactCard.vue'
import quote from '@/store/modules/quote'
import {
  CustomerAccount,
  CustomerAccountDetailEntity,
  CustomerAccountGroup,
  CustomerAccountRequest,
} from '@/models/dto/CustomerAccount'
import CompanySidebarDetail from '@/components/CompanySidebarDetail.vue'
import CUCustomerAccountCard from '@/components/CUCustomerAccountCard.vue'
import { EventBus } from '@/utils/eventBus'
import { Note } from '@/models/dto/Note'
import CreateContactSidebar from '@/components/CreateContactSidebar.vue'
import { Quote } from '@/models/dto/Quote'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({
  components: {
    CustomerContactSearch,
    DetailsContent,
    CUContactCard,
    CUCustomerAccountCard,
  },
})
export default class QuoteCustomerDetails extends Vue {
  loadedContacts = []
  loadedCompanies = []
  loading = false
  currentContact: any = null
  addingBilling = false
  addingCompany = false
  displayBookingContactError = false
  isContact: boolean = null

  state = quote
  formatFullName = formatFullName

  @Watch('displayBookingContactError', { immediate: false })
  handleDisplayBookingContactErrorUpdate(
    displayBookingContactError: boolean
  ): void {
    if (!displayBookingContactError) {
      this.$emit('quote-customer-details:reset-errors')
    }
  }

  @Watch('currentContactWatcher', {
    immediate: true,
  })
  handleUpdateCurrentContact(): void {
    this.currentContact =
      this.companyContact ||
      this.bookingContact ||
      this.billingContact ||
      new SimpleContact({ customerId: -1 })
    this.isContact = !this.companyContact
  }

  get currentContactWatcher(): string {
    return `${this.companyContact?.customerAccountId}${this.bookingContact?.customerId}${this.billingContact?.customerId}${this.companyContact?.name}${this.bookingContact?.firstName}${this.bookingContact?.lastName}${this.billingContact?.firstName}${this.billingContact?.lastName}`
  }

  get isBookingContactSelected(): boolean {
    return (
      this.bookingContact?.customerId &&
      this.currentContact?.customerId === this.bookingContact?.customerId
    )
  }

  get isBillingContactSelected(): boolean {
    return (
      this.billingContact?.customerId &&
      this.currentContact?.customerId === this.billingContact?.customerId
    )
  }

  get note(): Note {
    return (
      this.currentContact?.customerNotes?.[0] ||
      this.currentContact?.customer?.customerNotes?.[0] ||
      this.currentContact?.customerAccountNotes?.[0]
    )
  }

  async handleUpdateSendQuotesAsInvoice(
    sendQuotesAsInvoice: boolean
  ): Promise<void> {
    if (this.isContact) {
      this.currentContact.sendQuotesAsInvoice = sendQuotesAsInvoice
      const contactRequest = new ContactRequest(this.currentContact)
      contactRequest.address = null
      await contactClient.update(this.currentContact.userId, contactRequest)
      return
    }
    this.currentContact.sendQuotesAsInvoice = sendQuotesAsInvoice
    const customerAccountRequest = new CustomerAccountRequest(
      this.currentContact
    )
    customerAccountRequest.address = null
    customerAccountRequest.customers = null
    await customerAccountClient.update(
      this.currentContact.customerAccountId,
      customerAccountRequest
    )
  }

  get companyContact(): CustomerAccount {
    return this.state.quote.customerAccountId !== null && this.loadedCompanies
      ? this.loadedCompanies.find(
          (contact) =>
            contact.customerAccountId === this.state.quote.customerAccountId
        )
      : null
  }

  get bookingContact(): SimpleContact {
    return this.state.quote.customerId && this.loadedContacts
      ? this.loadedContacts.find(
          (contact) => contact.customerId === this.state.quote.customerId
        )
      : null
  }

  get billingContact(): SimpleContact {
    return this.state.quote.billingCustomerId && this.loadedContacts
      ? this.loadedContacts.find(
          (contact) => contact.customerId === this.state.quote.billingCustomerId
        )
      : null
  }

  get customerAccountContacts(): SimpleContact[] {
    if (!this.companyContact || !this.loadedContacts) {
      return []
    }
    return this.loadedContacts.filter(
      (contact) =>
        contact.customerAccountId === this.companyContact.customerAccountId
    )
  }

  // If a company contact is selected, return a list of
  // all contacts associated with that company. Otherwise if
  // a booking contact is selected, get the company id of the
  // associated company, and return only contacts from that
  // booking contact's customer account
  get filteredContacts(): SimpleContact[] {
    if (!this.currentCustomerAccountId) {
      return this.loadedContacts
    }
    return this.loadedContacts.filter(
      (contact) => contact.customerAccountId === this.currentCustomerAccountId
    )
  }

  get currentCustomerAccountId(): number {
    let customerAccountId
    if (this.companyContact) {
      customerAccountId = this.companyContact.customerAccountId
    } else if (this.bookingContact) {
      customerAccountId = this.bookingContact.customerAccountId
    }

    return customerAccountId
  }

  get isConverted(): boolean {
    return !!this.state?.quote?.isConverted
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  bookingActions: Action[] = [
    ...(this.canViewContacts
      ? [{ label: 'Edit Contact', event: 'contact:edit' }]
      : []),
    { label: 'Change Contact', event: 'contact:change' },
  ]

  billingActions: Action[] = [
    ...(this.canViewContacts
      ? [{ label: 'Edit Contact', event: 'contact:edit' }]
      : []),
    { label: 'Change Contact', event: 'contact:change' },
    { label: 'Remove Contact', event: 'contact:delete' },
  ]

  companyActions: Action[] = [
    ...(this.canViewContacts
      ? [{ label: 'Edit Company', event: 'contact:edit' }]
      : []),
    { label: 'Change Company', event: 'contact:change' },
    { label: 'Remove Company', event: 'contact:delete' },
  ]

  async handleAddContact(options: {
    contact: SimpleContact
    contactType: ContactTypeKey
  }): Promise<void> {
    if (
      options.contactType !== 'Billing' &&
      options.contactType !== 'Booking'
    ) {
      return
    }
    let customerObj: Partial<Quote> = {}
    if (options.contactType === 'Booking') {
      customerObj = {
        customer: options.contact.customer,
        customerId: options.contact.customerId,
        customerName: formatFullName(options.contact),
        customerAccountId: options.contact.customerAccountId,
      }
      this.state.updateAllTrips({
        customerId: options.contact.customerId,
        customer: options.contact.customer,
      })
    }
    if (options.contactType === 'Billing') {
      customerObj = { billingCustomerId: options.contact.customerId }
    }
    const load = [this.loadContact(options.contact.customerId)]
    if (customerObj.customerAccountId) {
      load.push(this.loadCompany(customerObj.customerAccountId))
    }
    await Promise.all(load)
    this.state.updateQuote(customerObj)
    this.addingBilling =
      (options.contactType === 'Booking') === this.addingBilling
    sidebar.pop()
    this.displayBookingContactError = false
    this.$emit('quote-customer-details:reset-errors')
  }

  handleAddCompany(contact: CustomerAccount): void {
    this.state.updateQuote({
      customerAccountId: contact.customerAccountId,
    })
    this.loadCompany(contact.customerAccountId)
    this.addingCompany = false
  }

  async handleAddNewCompany(id: number): Promise<void> {
    if (this.bookingContact) {
      await contactClient.update(this.bookingContact.customerId, {
        ...this.bookingContact,
        customerAccountId: id,
      })
    }

    await this.loadCompany(id)
    const newCompany = this.loadedCompanies.find(
      (company) => company.customerAccountId === id
    )
    this.handleAddCompany(newCompany)
  }

  handleClickAddCompany(): void {
    if (this.currentCustomerAccountId) {
      this.state.updateQuote({
        customerAccountId: this.currentCustomerAccountId,
      })
      this.loadCompany(this.currentCustomerAccountId)
      return
    } else if (this.bookingContact) {
      sidebar.push({
        component: CreateContactSidebar,
        props: {
          isTripContact: false,
          companyOnly: true,
          isBooking: false,
        },
      })
    } else {
      this.addingCompany = true
    }
  }

  async handleClickContact(contact: SimpleContact): Promise<void> {
    const simpleContact = Object.assign({}, contact)
    const customer = await contactClient
      .byId(simpleContact.customerId)
      .then((response) => response.data.customer)
    contact.customer = customer
    this.currentContact = contact
    this.isContact = true
  }

  async handleClickCompany(
    company: CustomerAccountDetailEntity
  ): Promise<void> {
    const simpleCompany = Object.assign({}, company)
    const customer = await customerAccountClient
      .byId(simpleCompany.customerAccountId)
      .then((response) => response.data)
    this.currentContact = customer
    this.isContact = false
  }

  handleEditContact(contact: SimpleContact): void {
    const lockCompanyField = !!this.companyContact?.customerAccountId
    sidebar.push({
      component: ContactSidebarDetail,
      props: {
        userId: contact.customerId,
        simple: true,
        lockCompanyField,
      },
      on: {
        refresh: () => {
          this.loadContact(contact.customerId)
        },
      },
    })
  }

  handleChangeContact(isBooking: boolean): void {
    this.handleDeleteContact(isBooking)
    this.addingBilling = isBooking === this.addingBilling
  }

  handleDeleteContact(isBooking: boolean): void {
    if (isBooking) {
      this.state.updateQuote({
        customer: null,
        customerId: null,
        customerName: null,
      })
      this.state.updateAllTrips({ customerId: null, customer: null })
    } else {
      this.state.updateQuote({ billingCustomerId: null })
    }
  }

  handleEditCompany(contact: CustomerAccount): void {
    sidebar.push({
      component: CompanySidebarDetail,
      props: {
        customerAccountId: contact.customerAccountId,
      },
      on: {
        refresh: () => {
          this.loadCompany(contact.customerAccountId)
        },
      },
    })
  }

  handleChangeCompany(): void {
    this.state.updateQuote({
      customer: null,
      customerId: null,
      customerName: null,
      customerAccountId: null,
      customerAccountGroupId: null,
      customerAccountGroupName: null,
    })
    this.state.updateAllTrips({ customerId: null, customer: null })
  }

  handleDeleteCompany(): void {
    this.state.updateQuote({
      customerAccountId: null,
      customerAccountGroupId: null,
      customerAccountGroupName: null,
    })
  }

  handleChangeCompanyGroup(group: CustomerAccountGroup): void {
    if (!group) {
      return
    }
    this.state.updateQuote({
      customerAccountGroupId: group.customerAccountGroupId,
      customerAccountGroupName: group.name,
    })
  }

  handleHideCompanyGroups(): void {
    this.state.updateQuote({
      customerAccountGroupId: null,
      customerAccountGroupName: null,
    })
  }

  async handleCreateContact(id: number): Promise<void> {
    await this.loadContact(id)
    const contact = _cloneDeep(
      this.loadedContacts.find((a) => a.customerId === id)
    )
    this.state.updateQuote({
      customer: contact.customer,
      customerId: contact.customerId,
      customerName: formatFullName(contact),
    })
    this.state.updateAllTrips({
      customerId: contact.customerId,
      customer: contact.customer,
    })
  }

  handleCreateNote(note: Note, isContact: boolean): void {
    if (isContact) {
      this.handleCreateContactNote(note)
      return
    }
    this.handleCreateCompanyNote(note)
  }

  async handleCreateContactNote(note: Note): Promise<void> {
    this.currentContact.customerNotes = [note]
    await contactClient.update(
      this.currentContact.customerId,
      this.currentContact
    )
  }

  async handleCreateCompanyNote(note: Note): Promise<void> {
    this.currentContact.customerAccountNotes = [note]
    await customerAccountClient.update(this.currentContact.customerAccountId, {
      ...this.currentContact,
      customerAccountNotes: [note],
    })
  }

  handleCreateContactError(): void {
    return
  }

  validate(): boolean {
    if (!this.bookingContact) {
      this.displayBookingContactError = true
      return false
    }
    return true
  }

  reset(): void {
    this.displayBookingContactError = false
    this.$emit('quote-customer-details:reset-errors')
  }

  async loadContact(id: number): Promise<void> {
    this.loading = true
    const {
      data: { customer },
    } = await contactClient.byId(id)
    this.loadedContacts = this.loadedContacts.filter((c) => c.customerId !== id)
    this.loadedContacts.push(customer)
    this.loading = false
  }

  async loadCompany(id: number): Promise<void> {
    this.loading = true
    const { data } = await customerAccountClient.byId(id)
    this.loadedCompanies = this.loadedCompanies.filter(
      (c) => c.customerAccountId !== id
    )
    this.loadedCompanies.push(data)
    this.loading = false
  }

  async load(): Promise<void> {
    const promises = []
    if (!this.state.quote) {
      return
    }
    const { customerId, billingCustomerId, customerAccountId } =
      this.state.quote
    if (customerId) {
      promises.push(this.loadContact(customerId))
    }

    if (billingCustomerId) {
      promises.push(this.loadContact(billingCustomerId))
    }

    if (customerAccountId) {
      promises.push(this.loadCompany(customerAccountId))
    }

    await Promise.all(promises)
  }

  mounted(): void {
    EventBus.$on('contact-sidebar:update', this.handleAddContact)
    EventBus.$on('contact-search:new-company', this.handleAddNewCompany)
    EventBus.$on('company-sidebar-detail:submit-success', this.load)
    EventBus.$on('note:create', this.handleCreateNote)
    EventBus.$on(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
    this.load()
  }

  beforeDestroy(): void {
    EventBus.$off('contact-sidebar:update', this.handleAddContact)
    EventBus.$off('contact-search:new-company', this.handleAddNewCompany)
    EventBus.$off('company-sidebar-detail:submit-success', this.load)
    EventBus.$off('note:create', this.handleCreateNote)
    EventBus.$off(
      'update:send-quotes-as-invoice',
      this.handleUpdateSendQuotesAsInvoice
    )
  }
}
