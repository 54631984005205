
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import RecipientList from '@/components/RecipientList.vue'
import { Customer } from '@/models/dto/Customer'
@Component({
  components: { RecipientList },
})
export default class PaymentSidebarNoteAndRecipients extends Vue {
  @Prop() paymentNotes!: string
  @Prop() internalNotes!: string
  @Prop() paymentAmount!: number | string
  @Prop() processingFeePercentage!: number
  @Prop({ default: () => [] }) contacts!: Customer[]
  @Prop() sendEmailToCustomer!: boolean
  @Prop() processingFeeAmount!: number
  @Prop() totalPaymentAmount!: number

  currencyFilter = currencyFilter
}
