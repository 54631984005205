
import { Vue, Component } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import dayjs from 'dayjs'

@Component({})
export default class QuotePaymentDueDateSidebar extends Vue {
  date = ''
  loading = false

  clear(): void {
    this.loading = false
    sidebar.pop()
  }

  submit(): void {
    this.$emit(
      'save',
      this.date ? dayjs(this.date, 'YYYY-MM-DD').toISOString() : ''
    )
    this.loading = true
  }
}
