
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Stop, Trip } from '@/models/dto'
import TripItineraryStopCard from '@/components/TripItineraryStopCard.vue'
import TripItineraryGarageCard from '@/components/TripItineraryGarageCard.vue'
import { GarageTimes } from '@/models/dto/GarageTimes'

@Component({
  components: { TripItineraryGarageCard, TripItineraryStopCard },
})
export default class TripItinerary extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, default: false }) inlineTitle: boolean
  @Prop({ type: Boolean, default: false }) hideGarages: boolean
  @Prop({ type: Boolean, default: false }) hideStopTypeText: boolean
  @Prop({ type: Boolean, default: false }) hideIndex: boolean
  @Prop({ required: false, default: null }) color: string

  get stops(): Stop[] {
    return [...(this.trip.stops || [])].sort((a, b) =>
      a.orderIndex > b.orderIndex ? 1 : -1
    )
  }

  get garageTimes(): GarageTimes {
    return this.trip.garageTimes
  }
}
