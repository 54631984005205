var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column w-full",style:({
    minHeight: 'calc(100vh - 68px - 64px - 76px - 38px)',
  })},[(_vm.state.quote && !_vm.loading)?_c('CUScroll',{staticClass:"margin-l-6",attrs:{"horizontal":"","width":"calc(100% - 24px)"}},[_c('div',{staticClass:"d-flex align-top justify-start"},[(!_vm.companyContact && _vm.addingCompany)?_c('CustomerContactSearch',{key:"customer-contact-search-company",staticClass:"margin-t-6 margin-r-6",attrs:{"title":"Company Contact","hide-contacts":"","customer-account-id":_vm.currentCustomerAccountId},on:{"contact-search:company":_vm.handleAddCompany}}):_vm._e(),(!_vm.bookingContact && !_vm.companyContact)?_c('CustomerContactSearch',{key:"customer-contact-search-company-contact",staticClass:"margin-t-6 margin-r-6",attrs:{"title":"Select Company or Contact","error":_vm.displayBookingContactError},on:{"contact-search:contact":function($event){return _vm.handleAddContact({ contact: $event, contactType: 'Booking' })},"contact-search:company":_vm.handleAddCompany,"contact-search:reset-error":_vm.reset}}):_vm._e(),(_vm.companyContact)?_c('div',{staticClass:"d-flex flex-shrink-0"},[_c('CUCustomerAccountCard',{staticClass:"margin-t-6 margin-b-3 margin-r-6 border-1 border-solid cursor-pointer",class:{
            'border-green': _vm.currentContact === _vm.companyContact,
            'border-black': _vm.currentContact !== _vm.companyContact,
          },attrs:{"customer-account-id":_vm.companyContact.customerAccountId,"label":"Company","background":"white","actions":!_vm.isConverted && _vm.companyActions,"persist-select":"","group-id":_vm.state.quote.customerAccountGroupId},on:{"contact:click":function($event){return _vm.handleClickCompany(_vm.companyContact)},"contact:edit":function($event){return _vm.handleEditCompany(_vm.companyContact)},"contact:change":_vm.handleChangeCompany,"contact:delete":_vm.handleDeleteCompany,"group:change":_vm.handleChangeCompanyGroup,"group:hide":_vm.handleHideCompanyGroups}})],1):_vm._e(),(_vm.bookingContact)?_c('div',{staticClass:"d-flex flex-shrink-0"},[_c('CUContactCard',{staticClass:"margin-t-6 margin-b-3 margin-r-6 border-1 border-solid cursor-pointer",class:{
            'border-green': _vm.isBookingContactSelected,
            'border-gray-bg': !_vm.isBookingContactSelected,
          },attrs:{"contact":_vm.bookingContact,"label":"Booking","actions":!_vm.isConverted && _vm.bookingActions},on:{"contact:click":function($event){return _vm.handleClickContact(_vm.bookingContact)},"contact:edit":function($event){return _vm.handleEditContact(_vm.bookingContact)},"contact:change":function($event){return _vm.handleChangeContact(true)},"contact:delete":function($event){return _vm.handleDeleteContact(true)}}})],1):_vm._e(),(!_vm.bookingContact && _vm.companyContact)?_c('CustomerContactSearch',{key:"customer-contact-search-booking",staticClass:"margin-t-6 margin-r-6",attrs:{"title":"Booking Contact","customer-account-id":_vm.currentCustomerAccountId,"error":_vm.displayBookingContactError,"hide-companies":"","prepopulate-create-contact":{
          companyId: _vm.companyContact.customerAccountId,
          customerAccountId: _vm.companyContact.customerAccountId,
          customerAccountName: _vm.companyContact.name,
        }},on:{"contact-search:reset-error":function($event){_vm.displayBookingContactError = false},"contact-search:contact":function($event){return _vm.handleAddContact({ contact: $event, contactType: 'Booking' })}}}):_vm._e(),(_vm.billingContact)?_c('div',{staticClass:"d-flex flex-shrink-0"},[_c('CUContactCard',{staticClass:"margin-t-6 margin-b-3 margin-r-6 border-1 border-solid cursor-pointer",class:{
            'border-green': _vm.isBillingContactSelected,
            'border-gray-bg': !_vm.isBillingContactSelected,
          },attrs:{"contact":_vm.billingContact,"label":"Billing","actions":!_vm.isConverted && _vm.billingActions},on:{"contact:click":function($event){return _vm.handleClickContact(_vm.billingContact)},"contact:edit":function($event){return _vm.handleEditContact(_vm.billingContact)},"contact:change":function($event){return _vm.handleChangeContact(false)},"contact:delete":function($event){return _vm.handleDeleteContact(false)}}})],1):_vm._e(),(!_vm.billingContact && _vm.addingBilling)?_c('CustomerContactSearch',{key:"customer-contact-search-billing",staticClass:"margin-t-6 margin-r-6",attrs:{"title":"Billing Contact","hide-companies":"","prepopulate-create-contact":_vm.companyContact
            ? {
                companyId: _vm.companyContact.customerAccountId,
                customerAccountId: _vm.companyContact.customerAccountId,
                customerAccountName: _vm.companyContact.name,
              }
            : null,"customer-account-id":_vm.currentCustomerAccountId},on:{"contact-search:contact":function($event){return _vm.handleAddContact({ contact: $event, contactType: 'Billing' })}}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column justify-start align-start"},[(!_vm.billingContact && !_vm.addingBilling && !_vm.isConverted)?_c('CUButton',{staticClass:"font-bold",style:({ marginTop: '27px' }),attrs:{"text":"","unset-width":"","color":"green"},on:{"click":function($event){_vm.addingBilling = true}}},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"height":"20px","width":"20px"}},[_vm._v("add")]),_vm._v(" Add Billing Contact ")],1):_vm._e(),(
            !_vm.companyContact &&
            !_vm.addingCompany &&
            _vm.bookingContact &&
            !_vm.isConverted &&
            _vm.canViewContacts
          )?_c('CUButton',{staticClass:"font-bold",style:({
            marginTop: _vm.billingContact || _vm.addingBilling ? '27px' : '4px',
          }),attrs:{"text":"","unset-width":"","color":"green"},on:{"click":_vm.handleClickAddCompany}},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"height":"20px","width":"20px"}},[_vm._v("add")]),_vm._v(" Add Company ")],1):_vm._e()],1)],1)]):_c('CUSkeletonLoader',{staticClass:"margin-a-6 border-radius-20",attrs:{"width":"500px","height":"112px"}}),(_vm.currentContact.customerId !== -1)?_c('div',{staticClass:"d-flex flex-column margin-x-6 margin-b-6 flex-grow-1"},[_c('v-divider',{staticClass:"margin-b-6"}),_c('div',{staticClass:"padding-a-6 border-radius-20 background-gray-bg flex-grow-1"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"font-bold font-24"},[_vm._v(" "+_vm._s(_vm.currentContact.name || _vm.formatFullName(_vm.currentContact))+" ")])])]),_c('DetailsContent',{attrs:{"id":_vm.currentContact.customerId,"full-name":_vm.currentContact.name || _vm.formatFullName(_vm.currentContact),"company-id":_vm.currentContact.customerAccountId,"is-contact":_vm.isContact,"note":_vm.note,"object":_vm.currentContact}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }