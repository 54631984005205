
import { Trip, TripNote } from '@/models/dto'
import { Quote } from '@/models/dto/Quote'
import { metersToMiles } from '@/utils/distance'
import { countryPhoneFormatFilter, currencyFilter } from '@/utils/string'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import quotes from '@/services/quotes'
import { TripNoteTypes } from '@/utils/enum'
import dayjs from 'dayjs'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import QuickbooksConvertQuoteSidebar from './QuickbooksConvertQuoteSidebar.vue'
import quote from '@/store/modules/quote'

@Component({
  components: {
    ReservationTrackingMap,
    TripItinerary,
  },
})
export default class CharterUpReferralTripDetail extends Vue {
  @Prop({ required: true }) quote: Quote
  @Prop({ required: false, default: false }) isSelected: boolean

  acceptLoading = false
  rejectLoading = false

  currencyFilter = currencyFilter
  countryPhoneFormatFilter = countryPhoneFormatFilter

  get trip(): Trip {
    return this.quote.trips[0]
  }

  get travelTime(): string {
    const drivingTime = this.trip.drivingTime || 0
    const drivingTimeDuration = dayjs.duration(drivingTime, 'seconds')
    const hours = Math.floor(drivingTimeDuration.asHours())
    const minutes = drivingTimeDuration.minutes()

    return `${hours} h, ${minutes} min`
  }

  get travelDays(): number {
    const drivingTime = this.trip.drivingTime || 0
    const drivingTimeDuration = dayjs.duration(drivingTime, 'seconds')
    return Math.ceil(drivingTimeDuration.asDays())
  }

  get travelDistance(): string {
    return `${metersToMiles(this.trip.distance).toFixed(1)} mi.`
  }

  get requirements(): string {
    return '--'
  }

  get notes(): TripNote[] {
    return this.trip?.tripNotes.filter(
      (note) => note.noteType === TripNoteTypes.TRIP_NOTE_TYPE
    )
  }

  async handleAccept(): Promise<void> {
    if (auth.isQuickBooksAutomaticInvoiceCreation) {
      sidebar.push({
        component: QuickbooksConvertQuoteSidebar,
        title: 'Create QuickBooks Invoice',
        props: {
          userId: this.quote.customerId,
        },
        on: {
          'convert-quote': this.acceptReferral,
        },
      })
      return
    } else {
      this.acceptReferral()
    }
  }

  async acceptReferral(): Promise<void> {
    this.acceptLoading = true
    const disableInvoiceCreation = quote.disableQuickbooksInvoiceCreation
    const result = await quotes.acceptReferral(
      this.quote.referralId,
      disableInvoiceCreation
    )
    if (result?.data?.isRejected) {
      EventBus.$emit('snackbar:error', 'This lead is no longer available.')
      await this.handleReject()
    } else {
      this.acceptLoading = false
      this.$router.push({ name: 'reservations' })
    }
  }

  async handleReject(): Promise<void> {
    this.rejectLoading = true
    await quotes.rejectReferral(this.quote.referralId)
    this.rejectLoading = false
    this.$emit('reject')
  }
}
