
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Quote } from '@/models/dto/Quote'
import { BasePaymentTypeKeys, Trip } from '@/models/dto'
import CUCurrency from '@/components/CUCurrency.vue'
import { sumBy } from '@/utils/reducers'

@Component({
  components: { CUCurrency },
})
export default class PaymentSummaryPaymentTerms extends mixins(DateMixin) {
  @Prop({ required: true }) readonly quote!: Quote

  get trips(): Trip[] {
    return this.quote?.trips || []
  }

  isFullPayment(trip: Trip): boolean {
    return trip.paymentType?.key === BasePaymentTypeKeys.FULL_PAYMENT
  }

  isDownPayment(trip: Trip): boolean {
    return trip.paymentType?.key === BasePaymentTypeKeys.DOWN_PAYMENT
  }

  get totalDueToday(): number {
    return this.quote?.trips?.reduce(sumBy('amountDueNow'), 0) || 0
  }

  get totalDueLater(): number {
    const quoteTotal: number = this.quote?.amount || 0
    const tripTotal: number =
      this.quote?.trips?.reduce(sumBy('amountDueNow'), 0) || 0
    return Math.max(quoteTotal - tripTotal, 0)
  }

  paymentType(trip: Trip): string {
    if (this.isFullPayment(trip)) {
      return 'Pay in full'
    } else if (this.isDownPayment(trip)) {
      return `${trip.depositPercentage}% down payment`
    } else {
      return 'Pay later'
    }
  }

  paymentDueDate(trip: Trip): string {
    return trip.dueDate ? this.formatMediumDate(trip.dueDate) : '--'
  }
}
