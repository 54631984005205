
import { Vue, Component, Prop } from 'vue-property-decorator'
import ProcessingFeePercentageField from '@/components/ProcessingFeePercentageField.vue'
import { CompanyPaymentGatewayDetail } from '@/models/PaymentGateway'
@Component({
  components: {
    ProcessingFeePercentageField,
  },
})
export default class ConvertQuoteWithPaymentSidebarPaymentFields extends Vue {
  @Prop({ required: true }) paymentAmount!: string | number
  @Prop({ required: true }) paymentIntegrations!: CompanyPaymentGatewayDetail[]
  @Prop({ required: true })
  selectedPaymentIntegration!: CompanyPaymentGatewayDetail
  @Prop({ required: true }) selectedPaymentMethod!: {
    text: string
    value: string
  }
  @Prop({ required: false, type: Boolean, default: false }) enableAch!: boolean
  @Prop({ required: true }) processingFeePercentage!: number

  paymentMethods = [
    { text: 'Card', value: 'card' },
    { text: 'Bank', value: 'bank' },
  ]
}
