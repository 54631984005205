
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Action } from '@/models/dto/Action'
import { AxiosResponse } from 'axios'
import { Palette } from '@/models/dto/Palette'
import { Priority } from '@/models/dto/Priority'
import { Quote } from '@/models/dto/Quote'
import { Status } from '@/models/dto/Status'
import { formatFullName } from '@/utils/string'
import QuoteTagSelector from '@/components/QuoteTagSelector.vue'
import tclient from '@/services/type'
import uclient from '@/services/user'
import { UserDetail, UserResult } from '@/models/dto'
import CULabelField from '@/components/CULabelField.vue'
import { Tag } from '@/models/dto/Tag'
import { SourceCategory } from '@/utils/enum'
import auth from '@/store/modules/auth'
import { EventType } from '@/models/EventType'
import company from '@/services/company'
import FileUploadQuoteAndRes from './FileUploadQuoteAndRes.vue'
import QuoteState from '@/store/modules/quote'
import { File as CustomFile } from '@/models/dto/File'

@Component({
  components: { CULabelField, QuoteTagSelector, FileUploadQuoteAndRes },
})
export default class QuoteSidebarDetail extends mixins(DateMixin) {
  @Prop({ required: true }) readonly quote!: Quote
  @Prop({ required: false }) readonly tags!: Tag[]

  editManagedId = false
  customManagedId = this.managedId

  statuses: Status[] = []
  priorities: Priority[] = []
  eventTypes: EventType[] = []

  priority: Priority | null = null

  editReferredBy = false

  quoteOwner: UserDetail = null

  get quoteFiles(): CustomFile[] {
    return QuoteState.quote?.files || []
  }

  handleFilesUpdated(files: CustomFile[]): void {
    QuoteState.updateFiles(files)
  }

  get isCharterUpReferral(): boolean {
    return this.quote?.sourceCategory === SourceCategory.REFERRAL
  }

  handleManagedIdChange(): void {
    if (!this.customManagedId) {
      return
    }
    this.$emit('change:managed-id', this.customManagedId.substring(0, 50))
    this.editManagedId = false
  }

  handleQuoteStatusChange(action: Action): void {
    const status = this.statuses.find((s) => s.label === action.label)
    if (status.key === 'converted') {
      this.$emit('quote:convert')
      return
    }
    this.$emit('change:status', status)
  }

  handleQuotePriorityChange(action: Action): void {
    this.priority = this.priorities.find((p) => p.label === action.label)
    this.$emit('change:priority', this.priority)
  }

  handleQuoteEventTypeChange(action: Action): void {
    const eventType = this.eventTypes.find((e) => e.label === action.label)
    this.$emit('change:event-type', eventType)
  }

  handleQuoteOwnerChange(action: Action): void {
    this.$emit('change:owner', action.label)
  }

  handleReferredByChange(referredBy: string): void {
    this.$emit('change:referred-by', referredBy)
    this.editReferredBy = false
  }

  handleUpdateTags(tags: Tag[]): void {
    this.$emit('change:tag', tags)
  }

  get quoteId(): number {
    return this.quote?.quoteId || 0
  }

  get managedId(): string {
    return this.quote?.managedId || ''
  }

  get statusActions(): Action[] {
    return this.statuses.map((s) => ({ label: s.label, event: 'input' }))
  }

  get priorityActions(): Action[] {
    return this.priorities.map((p) => ({ label: p.label, event: 'input' }))
  }

  get eventTypeActions(): Action[] {
    return this.eventTypes.map((e) => ({ label: e.label, event: 'input' }))
  }

  get quoteStatusColor(): Palette {
    return Status.color(this.status)
  }

  get referredBy(): string {
    return this.quote?.referredBy || ''
  }

  get owner(): string {
    const name = formatFullName(this.quoteOwner)
    return name || '--'
  }

  get createdDate(): string {
    return this.quote.createdOn
      ? this.formatMediumDate(this.quote.createdOn, {
          tz: auth.getUserTimeZone,
        })
      : '--'
  }

  get source(): string {
    return this.quote?.source
  }

  get lastActivity(): string {
    return this.quote.updatedOn
      ? this.formatMediumDate(this.quote.updatedOn, {
          tz: auth.getUserTimeZone,
        })
      : '--'
  }

  get status(): Status {
    return this.quote.quoteStatus || this.statuses[0] || null
  }

  get eventType(): EventType {
    if (this.quote.eventTypeId) {
      return this.eventTypes.find((e) => e.id === this.quote.eventTypeId)
    }
    return {
      id: -1,
      label: '--',
      companyId: this.quote.companyId,
      orderIndex: -1,
    }
  }

  async loadCompanyEventTypes(): Promise<void> {
    const {
      data: { eventTypes },
    } = await company.getCompanyEventTypes(auth.getCompanyId)
    eventTypes.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
    this.eventTypes = [
      {
        id: null,
        label: '--',
        companyId: auth.getCompanyId,
        orderIndex: -1,
      },
      ...eventTypes,
    ]
  }

  mounted(): void {
    tclient
      .statusTypes()
      .then((res: AxiosResponse<Status[]>) => res.data)
      .then((types: Status[]) => {
        this.statuses = types
      })
    tclient
      .priorityTypes()
      .then((res: AxiosResponse<Priority[]>) => res.data)
      .then((types: Priority[]) => {
        this.priorities = types
        this.priority = this.quote.quotePriority || {
          id: 1,
          key: '',
          label: 'None',
        }
      })

    this.loadCompanyEventTypes()

    if (!this.quote.createdById) {
      return
    }

    uclient
      .byId(this.quote.createdById)
      .then((res: AxiosResponse<UserResult>) => res.data.user)
      .then((user: UserDetail) => (this.quoteOwner = user))
  }
}
