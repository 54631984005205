
import { Component, Prop, Vue } from 'vue-property-decorator'
import RecipientList from '@/components/RecipientList.vue'
import { validateRules } from '@/utils/rules'
import { Customer } from '@/models/dto/Customer'

@Component({
  components: { RecipientList },
})
export default class SendSoldOutSidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly contacts!: Customer[]
  recipients: Customer[] = []
  sendEmail = true

  cancel(): void {
    this.$emit('cancel')
  }

  async submit(): Promise<void> {
    const isValid = await validateRules(this)
    if (isValid) {
      const recipients = this.recipients.map((r) => ({
        firstName: r.firstName,
        email: r.email,
      }))
      this.$emit('notify', this.sendEmail ? recipients : [])
    }
  }

  mounted(): void {
    this.recipients = [...this.contacts]
  }
}
