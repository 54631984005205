
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Quote } from '@/models/dto/Quote'
import PaymentSummaryAmount from '@/components/PaymentSummaryAmount.vue'
import PaymentSummaryTerms from '@/components/PaymentSummaryTerms.vue'
import PaymentSummaryMethods from '@/components/PaymentSummaryMethods.vue'

@Component({
  components: {
    PaymentSummaryAmount,
    PaymentSummaryTerms,
    PaymentSummaryMethods,
  },
})
export default class PaymentSummary extends Vue {
  @Prop({ required: true }) readonly quote!: Quote
  @Prop({ type: Boolean, default: false }) readonly horizontal!: boolean
}
