
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Trip, TripEstimation, TripContact } from '@/models/dto'
import {
  formatFullName,
  cleanEmptyHtml,
  countryPhoneFormatFilter,
} from '@/utils/string'
import TripDetailsGrid from '@/components/TripDetailsGrid.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import { TripNoteTypes } from '@/utils/enum'
import TripPricingSummary from './TripPricingSummary.vue'
import ReservationTrackingMap from './ReservationTrackingMap.vue'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: {
    TripItinerary,
    TripDetailsGrid,
    TripPricingSummary,
    ReservationTrackingMap,
  },
})
export default class QuoteSummarySidebarTripSummary extends Vue {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly tripEstimation!: TripEstimation

  sidebar = sidebar

  get contactId(): number {
    return this.trip.customerId || 0
  }

  get tripContactString(): string {
    const name = formatFullName(this.tripContact)
    const phone = countryPhoneFormatFilter(
      this.tripContact?.phone,
      this.tripContact.phoneCountryKey
    )
    return `${name}, ${phone}`
  }

  get notesEmpty(): boolean {
    return !this.tripNotes && !this.internalNotes && !this.driverNotes
  }

  get tripContact(): TripContact {
    return this.trip?.tripContact
  }

  get tripNotes(): string {
    const type = TripNoteTypes.TRIP_NOTE_TYPE
    const htmlNote = this.trip.tripNotes?.find(
      (note) => note.noteType === type
    )?.html
    return cleanEmptyHtml(htmlNote)
  }

  get internalNotes(): string {
    const type = TripNoteTypes.INTERNAL_NOTE_TYPE
    const htmlNote = this.trip.tripNotes?.find(
      (note) => note.noteType === type
    )?.html
    return cleanEmptyHtml(htmlNote)
  }

  get driverNotes(): string {
    const type = TripNoteTypes.DRIVER_NOTE_TYPE
    const htmlNote = this.trip.tripNotes?.find(
      (note) => note.noteType === type
    )?.html
    return cleanEmptyHtml(htmlNote)
  }
}
