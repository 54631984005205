
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { numberToPixels } from '@/utils/style'
import { isoToDate, monthName } from '@/utils/date'
import { CalendarEvent } from '@/models/Calendar'
import dayjs from 'dayjs'
import CURecurrenceCalendarEvent from './CURecurrenceCalendarEvent.vue'

@Component({
  components: { CURecurrenceCalendarEvent },
})
export default class CUCalendar extends mixins(DateMixin) {
  @Prop({ required: false, default: 600 }) readonly height!: string | number
  @Prop({ type: Boolean, default: false }) readonly showNavigation!: boolean
  @Prop({ type: Boolean, default: false }) readonly showHeader!: boolean
  @Prop({ required: false, default: () => [] })
  readonly events!: CalendarEvent[]
  @Prop({
    required: true,
    type: String,
    default: () => dayjs().format('YYYY-MM-DD'),
  })
  readonly tripStart!: string
  @Prop({ required: true, type: String }) readonly tripStartTimezone!: string
  @Prop({ required: false, type: String }) readonly tripEnd: string
  @Prop({ required: true, type: String }) readonly tripEndTimezone!: string
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  @Watch('tripStart', { immediate: true })
  onTripStartChange(tripStart: string): void {
    this.date = dayjs(tripStart).format('YYYY-MM-DD')
  }

  date = this.now

  get now(): string {
    return isoToDate(dayjs())
  }

  get header(): string {
    return `${monthName(this.date)} ${dayjs(this.date).year()}`
  }

  get calendarHeight(): string {
    return numberToPixels(this.height)
  }

  isOriginalTrip(start: string): boolean {
    return dayjs(start).isSame(dayjs(this.tripStart), 'day')
  }

  handleNavigatePrevMonth(): void {
    const current = dayjs(this.date)
    this.date = isoToDate(current.subtract(1, 'month'))
  }

  handleNavigateNextMonth(): void {
    const current = dayjs(this.date)
    this.date = isoToDate(current.add(1, 'month'))
  }

  get timeLabel(): string {
    return `${this.formattedTime(
      this.tripStart,
      this.shouldHideMeridiem(this.tripStart, this.tripEnd),
      this.tripStartTimezone
    )}-${this.formattedTime(this.tripEnd, false, this.tripEndTimezone)}`
  }

  formattedTime(time: string, hideMeridiem = false, timeZone: string): string {
    const formatted = this.formatShortTime(time, {
      tz: timeZone,
      showMeridianLower: true,
    })
    return hideMeridiem ? formatted.slice(0, -2) : formatted
  }

  shouldHideMeridiem(start: string, end: string): boolean {
    if (!end) {
      return false
    }
    return (
      this.formatShortTime(start, { showMeridianLower: true }).slice(-2) ===
      this.formatShortTime(end, { showMeridianLower: true }).slice(-2)
    )
  }
}
