
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Trip, TripNote } from '@/models/dto/Trip'
import quote from '@/store/modules/quote'
import { TripNoteTypes } from '@/utils/enum'

@Component({})
export default class TripNotes extends Vue {
  @Prop({ required: true }) readonly tripIdx!: number
  @Prop({ required: false, default: false }) readonly isQuote!: boolean
  state = quote
  types = TripNoteTypes

  get trip(): Trip {
    return this.state.trips[this.tripIdx]
  }

  get isConverted(): boolean {
    return !!this.state?.quote?.isConverted
  }

  getTripNote(type: number): string {
    return (
      this.trip?.tripNotes?.find((note) => note.noteType === type)?.html || ''
    )
  }

  setTripNote(text: string, type: number, isHTML = false): void {
    const tripIdx = this.tripIdx
    const notes: TripNote[] = this.trip?.tripNotes || []
    const noteIdx = notes.findIndex((note) => note.noteType === type)
    const tripNote: TripNote = notes[noteIdx]

    if (tripNote) {
      const updatedNote = isHTML
        ? { ...tripNote, html: text }
        : { ...tripNote, note: text }
      const tripNotes = [...notes]
      tripNotes.splice(noteIdx, 1, updatedNote)
      this.state.updateTrip({ tripIdx, trip: { tripNotes } })
    } else {
      const updatedNote = {
        noteId: null,
        noteType: type,
        note: !isHTML ? text : null,
        html: isHTML ? text : null,
        createdOn: null,
        createdBy: null,
        active: null,
      }
      const tripNotes = [...notes, updatedNote]
      this.state.updateTrip({ tripIdx, trip: { tripNotes } })
    }
  }
}
