
import { Vue, Component } from 'vue-property-decorator'
import QuoteDetailConfirmConvert from '@/components/QuoteDetailConfirmConvert.vue'
import ConvertQuotePaymentAndSyncSidebar from '@/components/ConvertQuotePaymentAndSyncSidebar.vue'
import LogPaymentSidebar from '@/components/LogPaymentSidebar.vue'
import quote from '@/store/modules/quote'
import sidebar from '@/store/modules/sidebar'
import { currencyFilter } from '@/utils/string'
import { getRecurringAmountDueNow } from '@/utils/trip'
@Component({
  components: {
    QuoteDetailConfirmConvert,
    ConvertQuotePaymentAndSyncSidebar,
    LogPaymentSidebar,
  },
})
export default class QuoteDetailBeginConvert extends Vue {
  state = quote
  sidebar = sidebar
  collectPaymentNow = true
  currencyFilter = currencyFilter

  get dueNowAmount(): number {
    return quote.quote.trips.reduce(
      (amnt, trip) => getRecurringAmountDueNow(trip) + amnt,
      0
    )
  }

  pushToSidebarStack(componentName: string): void {
    switch (componentName) {
      case 'process-payment':
        this.sidebar.push({
          component: ConvertQuotePaymentAndSyncSidebar,
          title: 'Process Payment',
        })
        break
      case 'log-payment':
        this.sidebar.push({
          component: LogPaymentSidebar,
          title: 'Log Payment',
        })
        break
      case 'confirm-convert':
        this.sidebar.push({
          component: QuoteDetailConfirmConvert,
          title: 'Convert to Reservation',
        })
        break
    }
  }
}
