
import { Address } from '@/models/dto/Address'
import { isCardNumberValidLength, isNotEmptyInput } from '@/utils/validators'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { states } from '@/utils/states'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { CustomerPaymentProfile } from '@/models/dto/PaymentProfile'

@Component({
  components: {
    AutocompleteAddress,
  },
})
export default class ConvertQuotePaymentSidebarAuthNetFields extends Vue {
  @Prop({ required: true }) customerPaymentProfiles!: CustomerPaymentProfile[]
  @Prop({ required: true }) selectedPaymentProfile!: number
  @Prop({ required: true }) number!: string
  @Prop({ required: true }) expiration!: string
  @Prop({ required: true }) cvc!: string
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) address!: Address & { zip: string }
  @Prop({ required: true }) savePaymentMethod!: boolean

  isNotEmptyInput = isNotEmptyInput
  isCardNumberValidLength = isCardNumberValidLength
  states = states

  get shouldAddNewCard(): boolean {
    return this.selectedPaymentProfile === -1
  }

  get addressState(): string {
    return this.address?.state
  }

  get addressZip(): string {
    return this.address?.zip
  }

  handleChangeAddressInput(input: string): void {
    const address = { ...this.address, street: input }
    this.$emit('input:address', address)
  }

  handleChangeAddress(address: Address): void {
    if (address) {
      const { street1, postalCode, ...rest } = address
      const addressEvent = { ...rest, street: street1, zip: postalCode }
      this.$emit('input:address', addressEvent)
    }
  }

  handleAddressStateInput(state: string): void {
    this.$emit('input:address', { ...this.address, state })
  }
}
