
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUContactCard from '@/components/CUContactCard.vue'
import quote from '@/store/modules/quote'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import CUCurrency from '@/components/CUCurrency.vue'
import PaymentSummary from '@/components/PaymentSummary.vue'
import { Tab } from '@/models/dto/Tab'
import { Action } from '@/models/dto/Action'
import QuoteSummarySidebarTripSummary from '@/components/QuoteSummarySidebarTripSummary.vue'
import { TripEstimation } from '@/models/dto'
import CUCustomerAccountCard from './CUCustomerAccountCard.vue'
import TripDetailsGrid from './TripDetailsGrid.vue'
import { getRecurringAmountDueNow } from '@/utils/trip'
import { cleanEmptyHtml } from '@/utils/string'
import client from '@/services/quotes'
import { Quote } from '@/models/dto/Quote'
import sidebar from '@/store/modules/sidebar'

@Component({
  components: {
    PaymentSummary,
    CUContactCard,
    CUCurrency,
    CUExpansionPanel,
    TripDetailsGrid,
    CUCustomerAccountCard,
  },
})
export default class QuoteSummarySidebarDetailExpanded extends Vue {
  @Prop({ required: true, default: null }) readonly actions: Action[]
  @Prop({ required: true, default: null }) readonly actionEvents: {
    [index: string]: (...args: unknown[]) => unknown
  }
  @Prop({ required: false, default: null }) readonly quoteId: number

  state = quote
  sidebar = sidebar

  get tabs(): Tab[] {
    return this.state.trips.map((trip, idx) => ({
      label: trip.routeName || `Trip ${1 + idx}`,
      component: QuoteSummarySidebarTripSummary,
      props: { trip, tripEstimation: this.tripEstimations[trip.tripId] },
    }))
  }

  get quoteNote(): string {
    const note = this.state.quote?.quoteNote
    if (!note) {
      return ''
    }
    return cleanEmptyHtml(note.html) || note.note || ''
  }

  get bookingContactId(): number {
    return this.state.quote?.customerId || 0
  }

  get tripEstimations(): Record<string, TripEstimation> {
    return this.state.estimations
  }

  get billingContactId(): number {
    return this.state.quote?.billingCustomerId || 0
  }

  get customerAccountId(): number {
    return this.state.quote?.customerAccountId || null
  }

  get customerAccountGroupId(): number {
    return this.state.quote?.customerAccountGroupId || null
  }

  get quoteTotal(): number {
    return this.state.quote?.amount || this.dueToday || 0
  }

  get dueToday(): number {
    return this.state.trips.reduce(
      (total, trip) => total + getRecurringAmountDueNow(trip),
      0
    )
  }

  async fetchQuoteInfo(): Promise<void> {
    try {
      const quoteRes = await client.byId(this.quoteId)
      if (!quoteRes?.data?.quote) {
        throw new Error('Malformed quote response when opening preview')
      }

      const quote = quoteRes.data.quote
      this.state.setQuote(new Quote({ ...quote }))
    } catch (error) {
      console.log(error)
    }
  }

  mounted(): void {
    if (this.quoteId) {
      this.fetchQuoteInfo()
    }
  }

  beforeDstroy(): void {
    this.$emit('close')
  }
}
