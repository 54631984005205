
import { Component, Prop, Vue } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import { Trip } from '@/models/dto'
import { sumBy } from '@/utils/reducers'
import { getRecurringTripTotal } from '@/utils/trip'

@Component({})
export default class PaymentTermsTableFooter extends Vue {
  @Prop({ required: true }) readonly trips!: Trip[]
  @Prop({ type: Boolean, default: true })
  readonly showSelect!: boolean
  currencyFilter = currencyFilter

  get tripTotal(): number {
    return this.trips.reduce((sum, trip) => {
      return sum + getRecurringTripTotal(trip)
    }, 0)
  }

  get dueNow(): number {
    return this.trips.reduce(sumBy('recurringAmountDueNow'), 0)
  }

  get dueLater(): number {
    return Math.max(this.tripTotal - this.dueNow, 0)
  }
}
