export const getPercentageOf = (
  num1: number,
  num2: number,
  roundTo?: number
): number => {
  let percentage = num2 === 0 ? 100 : (num1 / num2) * 100
  if (roundTo != null) {
    percentage = parseFloat(percentage.toFixed(roundTo))
  }
  return percentage
}

export const applyPercentageTo = (
  total: number,
  percentage: number,
  roundTo?: number
): number => {
  let amount = (total * percentage) / 100
  if (roundTo != null) {
    amount = parseFloat(amount.toFixed(roundTo))
  }
  return amount
}
