
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import quotes from '@/services/quotes'
import { buildMessage } from '@/utils/message'
import { busifyPayBaseUrl } from '@/utils/env'
import { debounce } from 'lodash'
import { WindowMessage } from '@/models/Message'
@Component({})
export default class BusifyPayQuoteConvertForm extends Vue {
  @Prop({ required: true }) quoteId!: number
  @Prop({ required: true }) paymentMethod!: 'card' | 'bank'
  @Prop({ required: true }) sessionId!: string
  @Prop({ required: true }) gatewayApiKey!: string
  @Prop({ required: false, default: false }) payFull!: boolean
  @Prop({ required: false, default: 0 }) processingFeePercentage!: number
  @Prop({ type: Boolean, default: false }) showSavedPaymentInstruments!: boolean
  @Prop({ required: true }) customerId!: number

  height = 150

  get paymentParameters(): Record<string, string | number> {
    return {
      quoteId: this.quoteId.toString(),
      sessionId: this.sessionId,
      paymentMethod: this.paymentMethod,
      payFull: this.payFull.toString(),
      gatewayApiKey: this.gatewayApiKey,
      processingFeePercentage: this.processingFeePercentage.toString(),
      customerId: this.customerId,
    }
  }

  src = ''
  displayError = false
  isMaxTransactionError = true

  submit(): void {
    const iframe = this.$refs.iframe as any
    const message = buildMessage(this.sessionId, 'submit-form')
    iframe.contentWindow.postMessage(message, busifyPayBaseUrl())
  }

  refresh(): void {
    window.location.reload()
  }

  loadPaymentFormFromParameters = debounce(async function () {
    try {
      const res = await quotes.initializeQuoteConversion(
        this.quoteId,
        this.sessionId,
        this.payFull,
        this.paymentMethod,
        this.processingFeePercentage,
        this.gatewayApiKey
      )

      if (res.status !== 200) {
        throw new Error('Failed to load payment form')
      } else {
        this.displayError = false
      }

      const currentTime = new Date().getTime()

      this.src = `${res.data.data}&showSavedPaymentInstruments=${this.showSavedPaymentInstruments}&customerId=${this.customerId}&timestamp=${currentTime}`
    } catch (e: any) {
      if (e.response?.data?.message?.includes('maximum transaction')) {
        this.isMaxTransactionError = true
      } else {
        this.isMaxTransactionError = false
      }
      this.displayError = true
    }
  }, 500)

  @Watch('paymentParameters', { immediate: true, deep: true })
  onParameterChange(): void {
    this.loadPaymentFormFromParameters()
  }

  handleMessageEvent(event: MessageEvent): void {
    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }

    if (data.messageName === 'height-updated') {
      const { height } = data.messageData
      this.height = height as number
    }
  }

  mounted(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }
}
