import { WindowMessage } from '@/models/Message'

export const buildMessage = (
  sessionId: string,
  messageName: string,
  messageData = null
): WindowMessage => {
  return {
    sessionId,
    messageName,
    messageData,
  }
}
