var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"margin-x-1 z-1"},[_c('CUIcon',{attrs:{"height":"14","width":"15","color":"gray-icon-light"}},[_vm._v("dot")])],1),_c('div',{ref:_vm.ref,staticClass:"position-relative padding-b-5 w-full"},[_c('div',{staticClass:"position-absolute top-9 border-solid border-l-1 border-r-0 border-y-0 border-bg-gray-4",style:({ height: _vm.lineHeight, transform: 'translate(-12px)' })}),_c('div',{staticClass:"font-14 font-semibold"},[_vm._v(_vm._s(_vm.title))]),(_vm.isDatetimeValid)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-14 font-bold text-green margin-r-4"},[_vm._v("Garage")]),_c('div',{staticClass:"d-flex flex-wrap font-14 w-three-fourths"},[_c('span',[_vm._v(_vm._s(_vm.date))]),(_vm.arrivalTime)?_c('div',{staticClass:"border-y-0 border-r-0 border-l-1 border-solid border-gray-icon-light h-14 w-1",style:({ margin: '3px 8px 0 8px' })}):_vm._e(),(_vm.arrivalTime)?_c('span',[_vm._v("Arrive "+_vm._s(_vm.arrivalTime))]):_vm._e(),_c('div',{staticClass:"border-y-0 border-r-0 border-l-1 border-solid border-gray-icon-light h-14 w-1",style:({ margin: '3px 8px 0 8px' })}),_c('span',[_vm._v(_vm._s(_vm.returning ? 'Arrive' : 'Depart')+" "+_vm._s(_vm.time))])])]):_vm._e(),(_vm.simpleNote)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between z-5"},[_c('div',{staticClass:"text-gray-text-darkest margin-b-0 border-radius-5",class:{
            'overflow-hidden h-20': !_vm.expanded,
            'h-full': _vm.expanded,
          },style:({
            maxWidth: 'fit-content',
            lineBreak: _vm.expanded ? 'normal' : 'anywhere',
          })},[_c('div',{staticClass:"text-gray-text-darkest font-14",domProps:{"innerHTML":_vm._s(_vm.expanded ? _vm.fullStopNote : _vm.simpleNote)}})]),(_vm.formattedNotes.canExpand)?_c('CUIcon',{staticClass:"cursor-pointer min-w-20 margin-l-1 margin-r-4 transition-property-transform transition-duration-300 transition-timing-function-ease border-radius-round hover:background-primary-10",class:{
            'rotate-n90': _vm.expanded,
          },attrs:{"color":"black","height":"20px","width":"20px"},on:{"click":function($event){return _vm.handleShowNote(_vm.formattedNotes)}}},[_vm._v(" keyboard_arrow_right ")]):_vm._e()],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }