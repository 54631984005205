
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Quote } from '@/models/dto/Quote'
import { Trip } from '@/models/dto'
import CUCurrency from '@/components/CUCurrency.vue'

@Component({
  components: { CUCurrency },
})
export default class PaymentSummaryPaymentAmount extends Vue {
  @Prop({ required: true }) readonly quote!: Quote

  get trips(): Trip[] {
    return this.quote?.trips || []
  }

  get quoteTotal(): number {
    return this.quote?.amount || 0
  }
}
