
import { Vue, Component } from 'vue-property-decorator'
import QuickbooksConvertQuoteSidebar from './QuickbooksConvertQuoteSidebar.vue'
import ConvertQuotePaymentSidebar from './ConvertQuotePaymentSidebar.vue'
import qstate from '@/store/modules/quote'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
@Component({
  components: { QuickbooksConvertQuoteSidebar, ConvertQuotePaymentSidebar },
})
export default class ConvertQuotePaymentAndSyncSidebar extends Vue {
  displayQuickbooksSidebar = false

  get quickbooksUserId(): number {
    return qstate?.quote?.customerId
  }

  submit(): void {
    if (auth.isQuickBooksAutomaticInvoiceCreation) {
      this.displayQuickbooksSidebar = true
      sidebar.setTitle('Create QuickBooks Invoice')
    } else {
      this.convertQuote()
    }
  }

  handleConversionError(): void {
    this.displayQuickbooksSidebar = false
    const quickbooksSidebar: any = this.$refs['quickbooks-sidebar']
    quickbooksSidebar.submittingWithoutSync = false
    quickbooksSidebar.submittingWithSync = false
  }

  convertQuote(): void {
    const convertQuotePaymentSidebar: any =
      this.$refs['convert-quote-payment-sidebar']
    convertQuotePaymentSidebar.convertQuote()
  }
}
