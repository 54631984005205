
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class CURecurrenceCalendarEvent extends Vue {
  @Prop({ required: true, default: '' }) readonly title!: string
  @Prop({ required: true, default: '' }) readonly timeLabel!: string
  @Prop({ required: true, default: '' }) readonly start!: string
  @Prop({ required: true, default: '' }) readonly end!: string
  @Prop({ required: false, default: false }) readonly isRecurrence!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  hoveredOver = false
  shown = false

  closeDeleteOption(): void {
    this.shown = false
  }

  get showDeleteOption(): boolean {
    if (this.isRecurrence && this.hoveredOver && !this.disabled) {
      return true
    }
    return false
  }
}
