import { AcceptService } from '@/services/acceptService'
import { apiBaseUrl } from '../utils/env'
import axios from 'axios'

export class PaymentGateway {
  acceptService = null
  initPromise = null
  paymentService = null

  constructor() {
    this.acceptService = new AcceptService()
    this.initPromise = null
    this.paymentService = null
  }

  init(
    companyId: number,
    checkoutPageId = false,
    paymentGatewayData
  ): Promise<any> {
    // Set the payment service
    if (this.initPromise === null) {
      this.initPromise = new Promise((resolve, reject) => {
        const params = {}

        if (paymentGatewayData) {
          if (paymentGatewayData.paymentGatewayTypeKey === 'auth_net') {
            this.paymentService = this.acceptService
            this.paymentService.setCredentials({
              clientKey: paymentGatewayData.clientKey,
              apiLoginID: paymentGatewayData.apiKey,
            })
          } else if (paymentGatewayData.paymentGatewayTypeKey === 'square') {
            return resolve({
              paymentGateway: {
                clientId: paymentGatewayData.clientKey,
                key: paymentGatewayData.paymentGatewayTypeKey,
                id: paymentGatewayData.companyPaymentGatewayId,
              },
            })
          } else if (
            paymentGatewayData.paymentGatewayTypeKey === 'busify_pay'
          ) {
            return resolve({
              paymentGateway: {
                clientId: paymentGatewayData.clientKey,
                key: paymentGatewayData.paymentGatewayTypeKey,
                id: paymentGatewayData.companyPaymentGatewayId,
              },
            })
          }
          resolve({
            paymentService: this.paymentService,
            paymentGateway: {
              key: paymentGatewayData.paymentGatewayTypeKey,
              id: paymentGatewayData.companyPaymentGatewayId,
            },
          })
        } else {
          // SEt from the default payment gateway
          const url = `https://${apiBaseUrl()}/paymentGateway/company/${companyId}/default`
          axios
            .get(url, { params })
            .then((response) => {
              if (response && response.data) {
                const payment = response.data.paymentGateways?.[0]

                if (payment.paymentGatewayTypeKey === 'auth_net') {
                  this.paymentService = this.acceptService
                  this.paymentService.setCredentials({
                    clientKey: payment.clientKey,
                    apiLoginID: payment.apiKey,
                  })
                } else if (payment.paymentGatewayTypeKey === 'square') {
                  return resolve({
                    paymentGateway: {
                      clientId: payment.clientKey,
                      key: payment.paymentGatewayTypeKey,
                      id: payment.companyPaymentGatewayId,
                    },
                  })
                } else if (payment.paymentGatewayTypeKey === 'busify_pay') {
                  return resolve({
                    paymentGateway: {
                      clientId: payment.clientKey,
                      key: payment.paymentGatewayTypeKey,
                      id: payment.companyPaymentGatewayId,
                    },
                  })
                }
                resolve({
                  paymentService: this.paymentService,
                  paymentGateway: {
                    key: payment.paymentGatewayTypeKey,
                    id: payment.companyPaymentGatewayId,
                  },
                })
              } else {
                reject(new Error('Company has no payment provider.'))
              }
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    }
    return this.initPromise
  }

  tokenize(
    paymentMethodData,
    reservationOrQuoteObject,
    paymentGatewayData = null
  ) {
    return new Promise((resolve, reject) => {
      if (paymentMethodData.activeMethod === 'check') {
        return resolve({
          tokens: [],
          paymentGateway: null,
        })
      }

      let cpId = reservationOrQuoteObject?.checkoutPage?.id || false
      if (reservationOrQuoteObject.companyCheckoutPageId) {
        cpId = reservationOrQuoteObject.companyCheckoutPageId
      }

      this.init(
        reservationOrQuoteObject.company?.companyId ||
          reservationOrQuoteObject.companyId,
        cpId,
        paymentGatewayData
      )
        .then(({ paymentGateway }) => {
          const promises = this._createPromises(
            reservationOrQuoteObject,
            paymentMethodData
          )
          Promise.all(promises)
            .then((tokens) => {
              resolve({
                tokens,
                paymentGateway,
              })
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  _createPromises(reservationOrQuoteObject, paymentMethodData) {
    const { tripId, trips } = reservationOrQuoteObject
    const array = []
    if (!this.paymentService) {
      return array
    }
    if (tripId || reservationOrQuoteObject?.trip?.tripId) {
      array.push(this.paymentService.tokenize(paymentMethodData))
    } else {
      for (let i = 0; i < trips.length; i++) {
        array.push(this.paymentService.tokenize(paymentMethodData))
      }
    }
    return array
  }
}
