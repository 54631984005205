
import { Reservation } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
@Component({})
export default class PaymentSidebarBulkHeader extends Vue {
  @Prop({ required: false, default: undefined })
  readonly reservations!: Reservation[]

  @Prop({ default: false, type: Boolean })
  readonly hideTotal!: boolean

  currencyFilter = currencyFilter

  get customerName(): string {
    if (!this.reservations.length) {
      return ''
    }
    if (this.reservations[0].customerAccountName) {
      return this.reservations[0].customerAccountName
    }
    return this.reservations[0].customerName
  }

  get customerLabel(): string {
    if (this.reservations?.[0]?.customerAccountName) {
      return 'Company'
    }
    return 'Customer'
  }

  get totalBalance(): number {
    return this.reservations.reduce((sum, res) => sum + res.balance, 0)
  }
}
