
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Stop } from '@/models/dto'
import { stopTitle } from '@/utils/stop'
import { FormattedNote } from '@/models/dto/Note'
import { getWidthOfTextInFont } from '@/utils/string'
import auth from '@/store/modules/auth'
import colors from '@/scss/_colors-export.scss'

@Component({})
export default class TripItineraryStopCard extends mixins(DateMixin) {
  @Prop({ required: true }) readonly stop!: Stop
  @Prop({ type: Boolean, default: false }) readonly noLine!: boolean
  @Prop({ type: Boolean, default: false }) readonly inlineTitle!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideStopTypeText!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideIndex!: boolean
  @Prop({ required: false, default: null }) color: string

  expanded = false
  canvas = null
  isMounted = false
  debounce = null
  forceRefresh = 0
  addressWidth = null

  get lineHeight(): string {
    return this.noLine ? '0px' : '100%'
  }

  get ref(): string {
    return `itinerary-note-${this.stop?.stopId || 0}`
  }

  get fullStopNote(): string {
    return (
      this.stop.stopNotes?.map((el) => el.html || el.note)?.join(' ') ||
      this.stop.notes
    )
  }

  get formattedNotes(): FormattedNote {
    const r = this.forceRefresh
    const clearNewLines = /<[ul,li,/ul,/li,p,/p,br]*>/g
    if (!this.stop || !this.isMounted) {
      return null
    }
    if (!this.stop.stopNotes?.length && this.stop.notes) {
      return {
        canExpand: this.canExpand(this.stop.notes),
        notes: this.stop.notes.replaceAll(clearNewLines, ' '),
      }
    }
    const reducedNote = this.stop.stopNotes
      .map((el) => el.html || el.note)
      .join(' ')
    return {
      canExpand: this.canExpand(reducedNote),
      notes: reducedNote.replaceAll(clearNewLines, ' '),
    }
  }

  canExpand(note: string): boolean {
    if (!note) {
      return false
    }
    return (
      getWidthOfTextInFont(
        note.replaceAll(/<[^>]*>/g, ''),
        '14px',
        '400',
        this.canvas
      ) >
        this.addressWidth + 24 || note.includes('<ul>')
    )
  }

  handleShowNote(note: FormattedNote): void {
    this.expanded = note.canExpand && !this.expanded
  }

  get simpleNote(): string {
    return this.formattedNotes?.notes || ''
  }

  get timeZone(): string {
    return this.stop.address?.timeZone || auth.getUserTimeZone
  }

  isPickup(stop: Stop): boolean {
    return !!stop.pickupDatetime
  }

  isDropoff(stop: Stop): boolean {
    return !!stop.dropoffDatetime
  }

  isPickupAndDropoff(stop: Stop): boolean {
    return !!stop.dropoffDatetime && !!stop.pickupDatetime
  }

  dotColor(stop: Stop): string {
    return (
      this.color || (stop.active ? colors['green'] : colors['gray-icon-light'])
    )
  }

  title(stop: Stop): string {
    return stopTitle(stop)
  }

  address(stop: Stop): string {
    return stop.address?.name || stop.address?.addressName || ''
  }

  stopType(stop: Stop): string {
    return this.isDropoff(stop) ? 'Dropoff' : 'Pickup'
  }

  timestamp(stop: Stop): string {
    return this.isDropoff(stop) ? stop.dropoffDatetime : stop.pickupDatetime
  }

  isDatetimeValid(stop: Stop): boolean {
    return !!this.date(stop) && !!this.time(stop)
  }

  date(stop: Stop): string {
    const ts = this.timestamp(stop)
    return !ts ? null : this.formatWeekdayShortDate(ts, { tz: this.timeZone })
  }

  time(stop: Stop): string {
    const ts = this.timestamp(stop)
    return !ts
      ? null
      : this.formatShortTime(ts, {
          tz: this.timeZone,
          showTimezone: true,
          showMeridianUpper: true,
        })
  }

  hasSpotTime(stop: Stop): boolean {
    return !!stop.spotTime?.spotTime
  }

  spotTime(stop: Stop): string {
    return this.formatShortTime(stop.spotTime.spotTime.toString(), {
      tz: this.timeZone,
      showTimezone: true,
      showMeridianUpper: true,
    })
  }

  handleResize(): void {
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(() => {
      this.forceRefresh += 1
      const newWidth = (this.$refs[this.ref] as any)?.scrollWidth
      if (newWidth) {
        this.addressWidth = newWidth
      }
    }, 250)
  }

  mounted(): void {
    window.addEventListener('resize', this.handleResize)
    this.isMounted = true
    this.addressWidth = (this.$refs[this.ref] as any)?.scrollWidth || 0
  }
}
