
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Days } from '@/utils/enum'

@Component({})
export default class CUDayPicker extends Vue {
  @Prop({ required: true }) readonly value!: Days[]
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  get dayOptions(): Days[] {
    return Object.values(Days)
  }

  isDaySelected(day: Days): boolean {
    return this.value.includes(day)
  }

  handleDayToggle(day: Days): void {
    if (this.disabled) {
      return
    }
    if (this.isDaySelected(day)) {
      this.$emit(
        'input',
        this.value.filter((d) => d !== day)
      )
    } else {
      this.$emit('input', [...this.value, day])
    }
  }
}
