import { Recurrence } from '@/models/dto/Recurrence'
import { Days, RecurrenceType } from '@/utils/enum'
import { isInvalidRange, isSameDay } from '@/utils/date'
import dayjs from 'dayjs'
import { isDayIn, isWeekday } from '@/utils/days'

export const toDays = (recurrence: Recurrence): Days[] => {
  const weekdays = []
  if (!recurrence) {
    return weekdays
  }
  if (recurrence.repeatOnSunday) {
    weekdays.push(Days.SUNDAY)
  }
  if (recurrence.repeatOnMonday) {
    weekdays.push(Days.MONDAY)
  }
  if (recurrence.repeatOnTuesday) {
    weekdays.push(Days.TUESDAY)
  }
  if (recurrence.repeatOnWednesday) {
    weekdays.push(Days.WEDNESDAY)
  }
  if (recurrence.repeatOnThursday) {
    weekdays.push(Days.THURSDAY)
  }
  if (recurrence.repeatOnFriday) {
    weekdays.push(Days.FRIDAY)
  }
  if (recurrence.repeatOnSaturday) {
    weekdays.push(Days.SATURDAY)
  }
  return weekdays
}

const dateRange = (
  recurrence: Recurrence,
  interval: dayjs.ManipulateType = 'day',
  ts?: string
): string[] => {
  const accumulator: string[] = ts ? [ts] : []

  if (isInvalidRange(recurrence.startDate, recurrence.endDate)) {
    return accumulator
  }

  for (
    let day = dayjs(recurrence.startDate);
    day.isSameOrBefore(recurrence.endDate);
    day = day.add(1, interval)
  ) {
    if (!ts || !isSameDay(day, ts)) {
      accumulator.push(day.toISOString())
    }
  }

  return accumulator
}

export const expandRecurrence = (
  recurrence: Recurrence,
  ts: string
): string[] => {
  if (!recurrence || !recurrence.startDate || !recurrence.endDate) {
    return [ts]
  }
  switch (recurrence.recurrenceType?.label) {
    case RecurrenceType.DAILY:
      return dateRange(recurrence, 'day', ts)
    case RecurrenceType.WEEKLY:
      return dateRange(recurrence, 'week', ts)
    case RecurrenceType.MONTHLY:
      return dateRange(recurrence, 'month', ts)
    case RecurrenceType.ANNUALLY:
      return dateRange(recurrence, 'year', ts)
    case RecurrenceType.EVERY_WEEKDAY:
      return dateRange(recurrence, 'day', ts).filter(
        (day) => isWeekday(day) || isSameDay(day, ts)
      )
    case RecurrenceType.CUSTOM:
      return dateRange(recurrence, 'day', ts).filter(
        (day) => isDayIn(day, toDays(recurrence)) || isSameDay(day, ts)
      )
    default:
      return [ts]
  }
}
