export const metersToMiles = (meters: number): number => {
  return meters / 1609.344
}

export const LatLngToMiles = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371
  const P = 0.017453292519943295
  const dLat = (lat2 - lat1) * P
  const dLon = (lon2 - lon1) * P
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * P) *
      Math.cos(lat2 * P) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  return (R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))) / 1.609344
}
