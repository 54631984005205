
import { Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import {
  PaymentMethod,
  PaymentMethodKeys,
  PaymentMethodType,
  Trip,
  Type,
} from '@/models/dto'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import { currencyFilter } from '@/utils/string'
import CUCheckbox from '@/components/CUCheckbox.vue'
import CUCurrency from '@/components/CUCurrency.vue'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import tclient from '@/services/type'
import termsService from '@/services/terms'
import { isAfterTodayTz } from '@/utils/date'
import {
  tripPickupTime,
  getRecurringTripTotal,
  getTripTotal,
  tripPickupTimeZone,
} from '@/utils/trip'
import PaymentTermsTableFooter from '@/components/PaymentTermsTableFooter.vue'
import { TripPaymentTerms } from '@/models/TripPaymentTerms'
import { distinctBy } from '@/utils/reducers'
import { OverageRate, RateType } from '@/models/dto/Rate'
import { validationRules } from '@/utils/rules'
import payments from '@/services/payments'
import PaymentLinkCell from './PaymentLinkCell.vue'
import { filter } from '@/utils/filter'
import { TableAction } from '@/models/TableAction'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import QuotePaymentPercentageSidebar from './QuotePaymentPercentageSidebar.vue'
import QuotePaymentAmountSidebar from './QuotePaymentAmountSidebar.vue'
import QuotePaymentDueDateSidebar from './QuotePaymentDueDateSidebar.vue'
import { applyPercentageTo } from '@/utils/math'
import deepClone from '@/utils/deepClone'
import quote from '@/store/modules/quote'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'
import { expirationDataType } from '@/utils/quote'
import app from '@/store/modules/app'
import { timeZones } from '@/utils/time'

@Component({
  components: {
    PaymentTermsTableFooter,
    CUSimpleTable,
    CUCurrency,
    CUCheckbox,
  },
})
export default class QuotePayment extends mixins(DateMixin) {
  validation = validationRules
  quote = quote
  app = app

  paymentMethodTypes: PaymentMethodType[] = []
  quotePaymentMethods: PaymentMethod[] = []
  selectedPaymentTerms: TripPaymentTerms[] = []
  paymentGateways = []

  overageRateTypes: RateType[] = []

  expirationTypes: Type[] = []

  FULL_PAYMENT_TYPE_ID = 1
  DOWN_PAYMENT_TYPE_ID = 2
  paymentTypes = []

  showOverageClearButton = false
  paymentMethodsError = false
  termsOfServiceError = false
  expirationFirstItems = [
    { text: 'First', value: true },
    { text: 'Last', value: false },
  ]
  terms = []

  // Map of tripId to whether that row should have an error
  // on its Balance Due Date
  tripPaymentTermsDateErrors: Record<number, boolean> = {}

  actions: TableAction[] = [
    {
      displayText: 'Set Due Now %',
      key: 'set-percent',
      action: (rows: TripPaymentTerms[]): void => {
        EventBus.$emit('quote-payment:set-percentage', rows)
      },
    },
    {
      displayText: 'Set Due Now $',
      key: 'set-amount',
      action: (rows: TripPaymentTerms[]): void => {
        EventBus.$emit('quote-payment:set-amount', rows)
      },
    },
    {
      displayText: 'Set Balance Due Date',
      key: 'set-due-date',
      action: (rows: TripPaymentTerms[]): void => {
        EventBus.$emit('quote-payment:set-due-date', rows)
      },
    },
  ]

  @Watch('quote.quote.paymentMethods')
  handleUpdatePaymentMethods(): void {
    this.quotePaymentMethods = [...quote.quote?.paymentMethods] || []
  }

  get trips(): Trip[] {
    return quote.quote?.trips || []
  }

  get termsId(): number {
    return quote.quote?.termsId
  }

  get paymentPolicy(): string {
    return quote.quote?.paymentPolicy
  }

  get requireSignatureUponCheckout(): boolean {
    return quote.quote?.requireSignatureUponCheckout
  }

  get expirationData(): {
    enabled: boolean
    type: number
    offset: number
    first: boolean
  } {
    return {
      enabled: quote?.quote?.enableExpiration,
      type: quote?.quote?.expirationType,
      offset: quote?.quote?.expirationOffset,
      first: quote?.quote?.expireAfterFirstSend,
    }
  }

  get expirationString(): string {
    const date = quote?.quote?.expirationDate
    const timezone =
      timeZones.find(
        (t) => t.zoneName === auth.getCompany?.address?.timeZone
      ) || timeZones[0]

    if (Object.values(this.expirationData).some((e) => e === null)) {
      return ''
    } else if (!date) {
      return '(Quote not sent yet)'
    }
    const sentDate = dayjs(date)
      .subtract(
        this.expirationData.offset,
        expirationDataType(this.expirationData.type)
      )
      .format()
    const formattedSentDate = this.formatShortDate(sentDate, {
      tz: timezone.zoneName,
    })
    const expiringOnText = isAfterTodayTz(date, timezone.zoneName)
      ? 'Expires'
      : 'Expired'
    const expiresDate = this.formatShortDate(date, { tz: timezone.zoneName })
    const formattedTime = this.formatShortTime(date.split('+')[0], {
      showMeridianLower: true,
    })
    const tz = dayjs(date).tz(timezone.zoneName).format('z')
    const first = this.expirationData.first ? 'First' : 'Last'
    return `(${first} sent on ${formattedSentDate}; ${expiringOnText} ${expiresDate} at ${formattedTime} ${tz})`
  }

  getTimezoneForTrip(trip: Trip): string {
    return trip?.stops[0]?.address?.timeZone || auth.getUserTimeZone
  }

  get tripPaymentTerms(): TripPaymentTerms[] {
    return this.trips.map((trip, tripIdx) => {
      let dueDate = trip.dueDate || null
      // check if date is already properly parsed
      if (!dayjs(trip.dueDate, 'MM/DD/YYYY', true).isValid()) {
        dueDate = trip.dueDate
          ? dayjs(trip.dueDate)
              .tz(this.getTimezoneForTrip(trip))
              .format('YYYY-MM-DDTHH:mm:ss')
          : null
      }

      return {
        ...trip,
        tripIdx,
        percentAmountDueNow: trip.depositPercentage,
        dateError: this.tripPaymentTermsDateErrors[trip.tripId],
        dueDate,
      }
    })
  }

  get allTripsDueNow(): boolean {
    return this.quote.trips.every((trip) => trip.depositPercentage === 100)
  }

  get quotePaymentMethodValues(): PaymentMethod[] {
    return this.quotePaymentMethods.filter((m) => m.isActive)
  }

  get paymentMethods(): PaymentMethod[] {
    const quotePaymentMethods = this.quotePaymentMethods.reduce(
      distinctBy('paymentMethodTypeId'),
      {}
    )
    return this.paymentMethodTypes.map((paymentMethodType) => {
      const quotePaymentMethod = quotePaymentMethods[paymentMethodType.id]
      const isLinkToSettingsIntegrations =
        paymentMethodType.key === PaymentMethodKeys.CREDIT_CARD ||
        (paymentMethodType.key === PaymentMethodKeys.ACH &&
          this.isBusifyPayEnabled)
      if (quotePaymentMethod) {
        return { ...quotePaymentMethod, isLinkToSettingsIntegrations }
      }
      const paymentMethodTypeId = paymentMethodType.id
      return {
        quoteId: null,
        processingFee: null,
        note: null,
        paymentMethodTypeId,
        paymentMethodType,
        isActive: false,
        isLinkToSettingsIntegrations,
      }
    })
  }

  get overageRate(): OverageRate {
    return (
      this.trips[0]?.rates[0] || {
        tripId: null,
        tripRateId: null,
        rateTypeId: null,
        rateType: null,
        amount: null,
      }
    )
  }

  get tripIds(): number[] {
    return quote.quote?.trips?.map((trip) => trip.tripId)
  }

  get isConverted(): boolean {
    return !!quote.quote.isConverted
  }

  get enablePONumber(): boolean {
    return !!quote.quote.enablePONumber
  }

  get isBusifyPayEnabled(): boolean {
    return this.paymentGateways.some(
      (gateway) => gateway.paymentGatewayTypeKey === 'busify_pay'
    )
  }

  @Watch('tripIds', { immediate: true })
  onTripIdChange(tripIds: number[]): void {
    if (!tripIds?.length) {
      return
    }

    for (const tripId in this.tripPaymentTermsDateErrors) {
      if (!tripIds.includes(Number(tripId))) {
        this.$delete(this.tripPaymentTermsDateErrors, tripId)
      }
    }

    const errorTripIds = Object.keys(this.tripPaymentTermsDateErrors)
    for (const tripId of tripIds) {
      if (!errorTripIds.includes(String(tripId))) {
        this.$set(this.tripPaymentTermsDateErrors, tripId, false)
      }
    }
  }

  getGatewayStatus(paymentMethod: PaymentMethod): boolean {
    switch (paymentMethod.paymentMethodType.key) {
      case PaymentMethodKeys.CREDIT_CARD:
        return !!this.paymentGateways.find(
          (gateway) =>
            gateway.paymentGatewayTypeKey === 'auth_net' ||
            gateway.paymentGatewayTypeKey === 'square' ||
            gateway.paymentGatewayTypeKey === 'busify_pay'
        )
      case PaymentMethodKeys.ACH:
        return !!this.paymentGateways.find(
          (gateway) => gateway.paymentGatewayTypeKey === 'busify_pay'
        )
      case PaymentMethodKeys.CHECK:
        return false
      case PaymentMethodKeys.WIRE:
        return false
      case PaymentMethodKeys.OTHER:
        return false
      default:
        return false
    }
  }

  fullTermsClick(): void {
    this.$router.push({
      name: 'settings.general',
      hash: '#terms',
    })
  }

  editTermsClick(): void {
    if (!this.termsId) {
      return
    }
    const route = this.$router.resolve({
      name: 'settings.general.terms.edit',
      params: {
        termsId: `${this.termsId}`,
      },
      hash: '#terms',
    })
    window.open(route.href, '_blank')
  }

  handlePaymentPolicyChange(paymentPolicy: string): void {
    this.$emit('input', { paymentPolicy })
  }

  pickupDateFormatter(tripPaymentTerms: TripPaymentTerms): string {
    const pickupDatetime = tripPickupTime(tripPaymentTerms)
    const pickupTimezone = tripPickupTimeZone(tripPaymentTerms)
    return !pickupDatetime
      ? '--'
      : this.formatMediumDate(pickupDatetime, { tz: pickupTimezone })
  }

  handleUpdatePONumber(poNumber: string): void {
    this.$emit('input', { poNumber })
  }

  handleUpdateOverageRate(overageRate: OverageRate): void {
    const trips = this.trips.map((trip) => ({
      ...trip,
      rates: [
        {
          ...overageRate,
          tripId: trip.tripId,
        },
      ],
    }))
    this.$emit('input', { trips })
  }

  handleTripPaymentTermsEdit(tripPaymentTerms: TripPaymentTerms): void {
    const { tripIdx, ...updated } = tripPaymentTerms
    const original = this.trips[tripIdx]
    const trips = [...this.trips]
    this.tripPaymentTermsDateErrors[tripPaymentTerms.tripId] = false

    let { dueDate } = updated
    if (dueDate) {
      dueDate = dayjs(dueDate)
        .tz(this.getTimezoneForTrip(this.trips[tripIdx]), true)
        .toISOString()
    }

    // If we've updated the due now dollar amount, figure out what percent
    // of the new due now amount is of the recurringTripTotal. Set the deposit
    // percentage accordingly
    if (original.depositAmount !== updated.depositAmount) {
      const depositAmount = Math.max(
        Math.min(updated.depositAmount, updated.recurringTripTotal),
        0
      )
      const depositPercentage = parseFloat(
        ((depositAmount / updated.recurringTripTotal) * 100).toFixed(2)
      )
      const amountDueNow = Number(
        ((depositPercentage * updated.total) / 100).toFixed(2)
      )

      const paymentTypeId =
        depositPercentage === 100
          ? this.FULL_PAYMENT_TYPE_ID
          : this.DOWN_PAYMENT_TYPE_ID
      const paymentType = this.paymentTypes.find((p) => p.id === paymentTypeId)
      trips.splice(tripIdx, 1, {
        ...updated,
        amountDueNow,
        recurringAmountDueNow: depositAmount,
        depositAmount,
        depositPercentage,
        paymentTypeId,
        paymentType,
        dueDate,
      })
    } else if (original.depositPercentage !== updated.depositPercentage) {
      // Otherwise we've updated the Due Now % value
      // Get the percentage, and apply it to the tripTotal and recurringTripTotal
      // respectively to figure out what we should set the amountDue and recurringAmountDue
      // values to
      const percentage = Math.max(Math.min(updated.depositPercentage, 100), 0)
      const tripTotal = getTripTotal(updated)
      const recurringTripTotal = getRecurringTripTotal(updated)
      const amountDueNow = applyPercentageTo(tripTotal, percentage)
      const recurringAmountDueNow = applyPercentageTo(
        recurringTripTotal,
        percentage,
        2
      )
      const paymentTypeId =
        updated.depositPercentage === 100
          ? this.FULL_PAYMENT_TYPE_ID
          : this.DOWN_PAYMENT_TYPE_ID
      const paymentType = this.paymentTypes.find((p) => p.id === paymentTypeId)
      trips.splice(tripIdx, 1, {
        ...updated,
        amountDueNow,
        recurringAmountDueNow,
        depositAmount: recurringAmountDueNow,
        depositPercentage: Number(percentage.toFixed(2)),
        paymentTypeId,
        paymentType,
        dueDate,
      })
    } else {
      const updatedTrip = { ...updated }
      if (dueDate) {
        updatedTrip.dueDate = dueDate
      }
      // We updated something else, like the due date
      trips.splice(tripIdx, 1, updatedTrip)
    }
    this.$emit('input', { trips })
    this.validateAndReset()
  }

  handleToggleSelectAll({ value }: { value: boolean }): void {
    this.paymentMethodsError = false
    const isActive = value
    let paymentMethods = deepClone(this.paymentMethods)
    paymentMethods = paymentMethods.map((paymentMethod) => ({
      ...paymentMethod,
      isActive,
    }))
    this.quotePaymentMethods = paymentMethods
    this.handleUpdateQuotePaymentMethods(paymentMethods)
  }

  handleUpdateAllowedPaymentMethod(val: {
    item: PaymentMethod
    value: boolean
  }): void {
    this.paymentMethodsError = false
    const quoteMethods = [...this.quotePaymentMethods]
    const index = quoteMethods.findIndex(
      (m) => m.paymentMethodTypeId === val.item.paymentMethodTypeId
    )

    if (index === -1) {
      quoteMethods.push({ ...val.item, isActive: val.value })
    } else {
      quoteMethods[index] = { ...val.item, isActive: val.value }
    }

    this.quotePaymentMethods = quoteMethods
    this.handleUpdateQuotePaymentMethods(quoteMethods)
  }

  handleUpdateQuotePaymentMethod(paymentMethod: PaymentMethod): void {
    const pm = this.quotePaymentMethods.find(
      (pm) => pm.paymentMethodType?.id === paymentMethod?.paymentMethodType?.id
    )
    const idx = this.quotePaymentMethods.indexOf(pm)
    if (idx > -1) {
      const paymentMethods = [...this.quotePaymentMethods]
      paymentMethods.splice(idx, 1, paymentMethod)
      this.handleUpdateQuotePaymentMethods(paymentMethods)
      this.quotePaymentMethods = paymentMethods
    }
  }

  handleUpdateQuotePaymentMethods(paymentMethods: PaymentMethod[]): void {
    this.$emit('input', { paymentMethods })
    this.validateAndReset()
  }

  handleUpdateEnablePayFullAmountNow(enablePayFullAmountNow: boolean): void {
    this.$emit('input', { enablePayFullAmountNow })
  }

  handleUpdateEnablePayLater(enablePayLater: boolean): void {
    this.$emit('input', { enablePayLater })
  }

  handleUpdateEnableExpiration(enableExpiration: boolean): void {
    this.$emit('input', { enableExpiration })
  }

  handleUpdateEnablePONumber(enablePONumber: boolean): void {
    if (enablePONumber) {
      this.handleToggleSelectAll({ value: false })
      this.handleUpdateEnablePayLater(false)
    }
    this.$emit('input', { enablePONumber })
  }

  handleUpdateExpirationOffset(expirationOffset: number): void {
    const offset = expirationOffset
      ? Number(Number(expirationOffset).toFixed())
      : null
    let date = quote?.quote?.expirationDate
      ? dayjs(quote.quote.expirationDate)
      : null
    let computedDate = quote?.quote?.expirationDate
    if (this.expirationData.type) {
      date =
        date
          ?.utc()
          .subtract(
            this.expirationData.offset,
            expirationDataType(this.expirationData.type)
          ) || null
      computedDate =
        date
          ?.utc()
          .add(offset, expirationDataType(this.expirationData.type))
          .format() || quote?.quote?.expirationDate
    }
    this.$emit('input', {
      expirationOffset: offset,
      expirationDate: computedDate,
    })
  }

  handleUpdateExpirationFirst(isFirst: boolean): void {
    this.$emit('input', { expireAfterFirstSend: isFirst })
  }

  handleUpdateRequireSignatureUponCheckout(
    requireSignatureUponCheckout: boolean
  ): void {
    this.$emit('input', { requireSignatureUponCheckout })
  }

  handleUpdateExpirationType(expirationType: number): void {
    const date =
      quote?.quote?.expirationDate && this.expirationData.type
        ? dayjs(quote.quote.expirationDate)
            .utc()
            .subtract(
              this.expirationData.offset,
              expirationDataType(this.expirationData.type)
            )
            .add(this.expirationData.offset, expirationDataType(expirationType))
            .format()
        : null
    this.$emit('input', { expirationType, expirationDate: date })
  }

  handleAddTermsOfService(): void {
    const route = this.$router.resolve({
      name: 'settings.general',
      hash: '#terms',
    })
    window.open(route.href, '_blank')
  }

  handleDeleteOverageRate(): void {
    const trips = this.trips.map((trip) => ({ ...trip, rates: [] }))
    this.$emit('input', { trips })
  }

  handleSetPercentage(paymentTerms: TripPaymentTerms[]): void {
    this.selectedPaymentTerms = paymentTerms
    sidebar.push({
      component: QuotePaymentPercentageSidebar,
      title: 'Set Due Now %',
      on: { save: (percentage: number) => this.setTripPercentages(percentage) },
    })
    return
  }

  setTripPercentages(percentage: number): void {
    const trips = [...this.trips]
    this.selectedPaymentTerms.map((trip) => {
      const recurringAmountDueNow =
        ((trip.recurringTripTotal || trip.total || 0) * percentage) / 100
      const amountDueNow = (trip.total * percentage) / 100
      const paymentTypeId =
        percentage === 100
          ? this.FULL_PAYMENT_TYPE_ID
          : this.DOWN_PAYMENT_TYPE_ID
      const paymentType = this.paymentTypes.find((p) => p.id === paymentTypeId)
      trips.splice(trip.tripIdx, 1, {
        ...trip,
        amountDueNow,
        recurringAmountDueNow,
        depositPercentage: percentage,
        depositAmount: recurringAmountDueNow,
        paymentTypeId,
        paymentType,
      })
    })
    this.$emit('input', { trips })
    this.selectedPaymentTerms = []
    sidebar.pop()
  }

  handleSetAmount(paymentTerms: TripPaymentTerms[]): void {
    this.selectedPaymentTerms = paymentTerms
    sidebar.push({
      component: QuotePaymentAmountSidebar,
      title: 'Set Due Now $',
      on: { save: (amount: number) => this.setTripAmounts(amount) },
    })
    return
  }

  setTripDueDates(date: string): void {
    const trips = [...this.trips]
    this.selectedPaymentTerms.map((trip) => {
      trips.splice(trip.tripIdx, 1, {
        ...trip,
        // Convert the date to the timezone of the trip, trips may have different timezones
        dueDate: date
          ? dayjs(date).tz(this.getTimezoneForTrip(trip), true).toISOString()
          : date,
      })
    })
    this.$emit('input', { trips })
    this.selectedPaymentTerms = []
    sidebar.pop()
  }

  handleSetDueDate(paymentTerms: TripPaymentTerms[]): void {
    this.selectedPaymentTerms = paymentTerms
    sidebar.push({
      component: QuotePaymentDueDateSidebar,
      title: 'Set Balance Due Date',
      on: { save: (date: string) => this.setTripDueDates(date) },
    })
    return
  }

  handleUpdateTermsId(termsId: number): void {
    quote.updateQuote({ termsId })
  }

  setTripAmounts(amount: number): void {
    const trips = [...this.trips]
    this.selectedPaymentTerms.map((trip) => {
      const recurringAmountDueNow = Math.max(
        Math.min(amount, trip.recurringTripTotal),
        0
      )
      const amountDueNow = Math.max(
        Math.min(amount / (trip.recurrenceTripCount || 1), trip.total),
        0
      )
      const depositPercentage = parseFloat(
        ((amountDueNow / trip.total) * 100).toFixed(1)
      )
      trips.splice(trip.tripIdx, 1, {
        ...trip,
        amountDueNow,
        recurringAmountDueNow,
        depositAmount: recurringAmountDueNow,
        depositPercentage,
      })
    })
    this.$emit('input', { trips })
    this.selectedPaymentTerms = []
    sidebar.pop()
  }

  tripPaymentTermsHeaders: SimpleTableColumn<TripPaymentTerms>[] = [
    {
      text: 'Trip',
      value: '',
      type: 'text',
      formatter: (row: TripPaymentTerms): string =>
        row.routeName || `Trip ${1 + row.tripIdx}`,
    },
    {
      text: 'Pickup Date',
      value: '',
      type: 'text',
      formatter: this.pickupDateFormatter,
    },
    {
      text: 'Recurrence',
      value: 'recurrenceTripCount',
      type: 'number',
      formatter: (row: TripPaymentTerms): string =>
        row.recurrenceTripCount ? String(row.recurrenceTripCount) : '1',
    },
    {
      text: 'Trip Total',
      value: '',
      type: 'text',
      align: 'end',
      formatter: (row: TripPaymentTerms): string =>
        `${currencyFilter(getRecurringTripTotal(row))}`,
    },
    {
      text: 'Due Now %',
      value: 'depositPercentage',
      type: 'number',
      valueType: 'percent',
      align: 'end',
      editable: !this.isConverted,
      reverse: true,
      fieldWidth: '90px',
      placeholder: '%',
      formatter: (row: TripPaymentTerms): string =>
        `${row.percentAmountDueNow}%`,
    },
    {
      text: 'Due Now $',
      value: 'depositAmount',
      valueType: 'currency',
      type: 'number',
      align: 'end',
      editable: !this.isConverted,
      reverse: true,
      fieldWidth: '90px',
      placeholder: '$',
      formatter: (row: TripPaymentTerms): string =>
        `${currencyFilter(row.recurringAmountDueNow)}`,
    },
    {
      text: 'Due Later $',
      value: '',
      type: 'number',
      align: 'end',
      formatter: (row: TripPaymentTerms): string =>
        `${currencyFilter(this.getDueLaterAmount(row))}`,
    },
    {
      text: 'Balance Due Date',
      value: 'dueDate',
      type: 'date',
      fieldWidth: '100px',
      align: 'end',
      editable: !this.isConverted,
      formatter: (row: TripPaymentTerms): string => row.dueDate,
    },
  ]

  paymentMethodHeaders: SimpleTableColumn<PaymentMethod>[] = [
    {
      text: 'Method',
      value: 'paymentMethodType/label',
      type: 'component',
      component: PaymentLinkCell,
      width: 120,
    },
    {
      text: 'Online Processing',
      value: '',
      type: 'text',
      placeholder: '--',
      width: 140,
      formatter: (row: PaymentMethod): string =>
        this.getGatewayStatus(row) ? 'Enabled' : '',
    },
    {
      text: 'Processing Fee',
      value: 'processingFee',
      type: 'number',
      valueType: 'percent',
      width: 118,
      editable: !this.isConverted,
      reverse: true,
      placeholder: '%',
    },
    {
      text: 'Payment Note and Instructions for Customer',
      value: 'note',
      type: 'text',
      editable: !this.isConverted,
      placeholder:
        'Include details per payment method on how to pay (e.g. wire account number, Venmo username)',
    },
  ]

  paymentMethodTypeFilter(types: PaymentMethodType[]): PaymentMethodType[] {
    return (types || []).filter((type) =>
      Object.values(PaymentMethodKeys).includes(type.key)
    )
  }

  getDueLaterAmount(trip: Trip): number {
    return getRecurringTripTotal(trip) - trip.recurringAmountDueNow
  }

  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
  }

  async loadAllTerms(): Promise<void> {
    const allTermsRes = await termsService.tableView({ page: 1, pageSize: -1 })
    this.terms = allTermsRes?.data?.resultList
  }

  async loadExpirationTypes(): Promise<void> {
    const res = await tclient.expiration()
    this.expirationTypes = res.data
  }

  reset(): void {
    this.paymentMethodsError = false
    this.termsOfServiceError = false
    for (const trip of this.tripPaymentTerms) {
      this.tripPaymentTermsDateErrors[trip.tripId] = false
    }
    this.$emit('quote-payment:reset-errors')
  }

  async validateAndReset(): Promise<void> {
    let isValid = true

    await this.$nextTick(() => {
      const activeMethods = this.quotePaymentMethodValues.filter(
        ({ isActive }) => isActive
      )
      if (!activeMethods.length) {
        isValid = false
      }
      for (const trip of this.tripPaymentTerms) {
        if (trip.percentAmountDueNow < 100 && !trip.dueDate) {
          isValid = false
        }
      }
    })

    if (isValid) {
      this.$emit('quote-payment:reset-errors')
    }
  }

  validate(): boolean {
    this.paymentMethodsError = false
    let isValid = true

    const activeMethods = this.quotePaymentMethodValues.filter(
      ({ isActive }) => isActive
    )
    if (!activeMethods.length && !this.enablePONumber) {
      this.paymentMethodsError = true
      isValid = false
    }

    for (const trip of this.tripPaymentTerms) {
      if (trip.depositAmount !== trip.recurringTripTotal && !trip.dueDate) {
        this.tripPaymentTermsDateErrors[trip.tripId] = true
        isValid = false
      }
    }

    if (!this.terms.length) {
      this.termsOfServiceError = true
      isValid = false
    }

    return isValid
  }

  async mounted(): Promise<void> {
    EventBus.$on('quote-payment:set-percentage', this.handleSetPercentage)
    EventBus.$on('quote-payment:set-amount', this.handleSetAmount)
    EventBus.$on('quote-payment:set-due-date', this.handleSetDueDate)

    const methodTypes = await tclient.paymentMethodTypes()
    this.paymentMethodTypes = this.paymentMethodTypeFilter(methodTypes.data)

    const rateTypes = await tclient.rateTypes()
    this.overageRateTypes = rateTypes.data

    const paymentGateways = this.loadPaymentGateways()
    const expirationTypes = this.loadExpirationTypes()
    const paymentRes = payments.getPaymentTypes()
    const termsRes = this.loadAllTerms()
    await Promise.all([paymentGateways, paymentRes, expirationTypes, termsRes])
    if (quote.quote?.paymentMethods?.length) {
      this.quotePaymentMethods = [...quote.quote.paymentMethods]
    } else {
      this.quotePaymentMethods = []
    }
  }

  beforeDestroy(): void {
    EventBus.$off('quote-payment:set-percentage', this.handleSetPercentage)
    EventBus.$off('quote-payment:set-amount', this.handleSetAmount)
    EventBus.$off('quote-payment:set-due-date', this.handleSetDueDate)
  }
}
