
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import qstate from '@/store/modules/quote'
import { currencyFilter } from '@/utils/string'
import payments from '@/services/payments'
import quotes from '@/services/quotes'
import rstate from '@/store/modules/reservation'
import rservice from '@/services/reservation'
import sidebar from '@/store/modules/sidebar'
import RecipientList from '@/components/RecipientList.vue'
import auth from '@/store/modules/auth'
import { EventBus } from '@/utils/eventBus'
import {
  PaymentMethodTypes,
  PaymentTypeIdKeys,
  Reservation,
  ReservationManualPaymentRequest,
} from '@/models/dto'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import PaymentSidebarBulkReservationsHeader from '@/components/PaymentSidebarBulkReservationsHeader.vue'
import { isNotEmptyInput } from '@/utils/validators'
import customer from '@/services/customer'
import QuickbooksConvertQuoteSidebar from './QuickbooksConvertQuoteSidebar.vue'

@Component({
  components: {
    RecipientList,
    PaymentSidebarHeader,
    PaymentSidebarBulkReservationsHeader,
  },
})
export default class LogPaymentSidebar extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly isSingleReservationPayment!: boolean
  @Prop({ default: undefined, required: false })
  readonly bulkReservations!: Reservation[]

  formData: {
    paymentAmount: string
    selectedPaymentMethod: PaymentMethodTypes
    referenceNumber: string
    paymentNotes: string
    internalNotes: string
    sendEmailToCustomer: boolean
  } = {
    paymentAmount: '0',
    selectedPaymentMethod: null,
    referenceNumber: '',
    paymentNotes: '',
    internalNotes: '',
    sendEmailToCustomer: true,
  }

  paymentMethods = []
  contacts = []
  displayedReservations = []
  loading = false
  auth = auth
  isNotEmptyInput = isNotEmptyInput

  @Watch('bulkReservations', { immediate: true })
  onBulkReservationChange(reservations: Reservation[]): void {
    this.displayedReservations = reservations
  }

  get isMultiReservationPayment(): boolean {
    return !!this.bulkReservations?.length
  }

  get dueNow(): number {
    if (this.isMultiReservationPayment || this.isSingleReservationPayment) {
      return this.reservationTotalBalance
    }
    const totalDueNow = qstate.quote.trips.reduce(
      (totalDue, trip) => totalDue + trip.recurringAmountDueNow,
      0
    )
    return parseFloat(totalDueNow.toFixed(2))
  }

  get reservationTotalBalance(): number {
    if (!this.isSingleReservationPayment && !this.isMultiReservationPayment) {
      return 0
    }
    if (this.isMultiReservationPayment) {
      const totalBalance = this.displayedReservations.reduce(
        (sum, r) => r.balance + sum,
        0
      )
      return parseFloat(totalBalance.toFixed(2))
    }
    return rstate.reservation.balance
  }

  get reservationIds(): number[] {
    if (this.isMultiReservationPayment) {
      return this.displayedReservations.map((r) => r.reservationId)
    } else {
      return [rstate.reservation.reservationId]
    }
  }

  get dueNowAmount(): string {
    if (this.isMultiReservationPayment || this.isSingleReservationPayment) {
      return currencyFilter(this.reservationTotalBalance)
    }
    return currencyFilter(this.dueNow)
  }

  get confirmationEmails(): string[] {
    if (!this.formData.sendEmailToCustomer) {
      return []
    }
    return this.contacts.map((u) => u.email)
  }

  @Watch('contacts')
  onContactsChange(): void {
    this.formData.sendEmailToCustomer = !!this.contacts.length
  }

  handleRemoveBulkReservation(reservationId: number): void {
    this.displayedReservations = this.displayedReservations.filter(
      (res) => res.reservationId !== reservationId
    )
    if (this.displayedReservations.length === 0) {
      sidebar.pop()
    }

    this.formData.paymentAmount = this.dueNow.toFixed(2)
  }

  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }

    if (this.isSingleReservationPayment || this.isMultiReservationPayment) {
      this.reservationSubmit()
    } else {
      this.handleQuoteSubmit()
    }
  }

  async reservationSubmit(): Promise<void> {
    const payload: ReservationManualPaymentRequest = {
      amount: String(this.formData.paymentAmount),
      billing: { check_number: this.formData.referenceNumber },
      notes: { note: this.formData.internalNotes || '' },
      customerNotes: { note: this.formData.paymentNotes || '' },
      payment_method: { key: this.formData.selectedPaymentMethod },
      reservationIds: this.reservationIds,
      sendEmail: this.formData.sendEmailToCustomer,
      confirmationEmails: this.confirmationEmails,
      isManual: true,
    }
    const resIds = this.isSingleReservationPayment
      ? [rstate.reservation.reservationId]
      : this.reservationIds

    try {
      this.loading = true
      const [res, _] = await Promise.all([
        rservice.addReservationPayment(payload),
        rservice.clearAmountDue(resIds),
      ])
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }

    sidebar.close()
  }

  handleQuoteSubmit(): void {
    if (auth.isQuickBooksAutomaticInvoiceCreation) {
      sidebar.push({
        component: QuickbooksConvertQuoteSidebar,
        title: 'Create QuickBooks Invoice',
        props: {
          userId: qstate.quote.customerId,
        },
        on: {
          'convert-quote': this.quoteSubmit,
        },
      })
      return
    } else {
      this.quoteSubmit()
    }
  }

  async quoteSubmit(): Promise<void> {
    this.loading = true

    try {
      const res = await quotes.convertManually({
        quoteId: qstate.quote.quoteId,
        payment_method: this.formData.selectedPaymentMethod,
        payment_type:
          Number(this.formData.paymentAmount) === qstate.quote.amount
            ? PaymentTypeIdKeys.FULL_PAYMENT
            : PaymentTypeIdKeys.DOWN_PAYMENT,
        amountPaid: parseFloat(
          this.formData.paymentAmount.replace(/[^0-9.]/g, '')
        ),
        payload: null,
        confirmationEmails: this.confirmationEmails,
        customer_notes: this.formData.paymentNotes,
        internal_notes: this.formData.internalNotes,
        reference_number: this.formData.referenceNumber,
        convertedBy: auth.userId,
        overrideExpiration: true,
      })
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message || 'Error logging payment'
      EventBus.$emit('snackbar:error', errorMessage)
    } finally {
      this.loading = false
    }
  }

  handleSubmitSuccess(): void {
    this.loading = false
    sidebar.close()
    if (this.isMultiReservationPayment) {
      EventBus.$emit('refresh-tableview')
      EventBus.$emit('snackbar:success', 'Payment logged successfully!')
    } else if (this.isSingleReservationPayment) {
      EventBus.$emit('refresh-reservation')
      EventBus.$emit('snackbar:success', 'Payment logged successfully!')
    } else {
      this.$router.push({ name: 'reservations' })
    }
  }

  async loadPaymentMethods(): Promise<void> {
    const res = await payments.getPaymentMethodTypes()
    this.paymentMethods = res.data.filter(
      (paymentMethod) =>
        paymentMethod.key !== 'refund' && paymentMethod.key !== 'billing'
    )
  }

  async loadContacts(): Promise<void> {
    let bookingContact
    let billingContactId

    if (this.isMultiReservationPayment) {
      const reservation = this.displayedReservations[0]
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = reservation.billingCustomerId
    } else if (this.isSingleReservationPayment) {
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = rstate.reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = rstate.reservation.billingCustomerId
    } else {
      bookingContact = qstate.quote.customer
      billingContactId = qstate.quote.billingCustomerId
    }

    this.contacts.push({ ...bookingContact, type: 'Booking' })
    if (billingContactId) {
      const { data: billingCustomer } = await customer.byId(billingContactId)
      this.contacts.push({ ...billingCustomer.customer, type: 'Billing' })
    }
  }

  validatePaymentAmount(val: string): boolean | string {
    if (!this.isSingleReservationPayment && !this.isMultiReservationPayment) {
      return true
    }
    if (Number(val) > Number(this.reservationTotalBalance.toFixed(2))) {
      return 'Payment Amount cannot be greater than Reservation Total'
    } else if (Number(val) <= 0) {
      return 'Payment Amount must be greater than 0'
    }
    return true
  }

  async mounted(): Promise<void> {
    await this.loadPaymentMethods()
    await this.loadContacts()
    this.formData.paymentAmount = this.dueNow.toFixed(2)
  }
}
