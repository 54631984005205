import { Reservation } from '@/models/dto'
import { InvoiceReservation, InvoiceResult } from '@/models/dto/Invoice'
import { Quote } from '@/models/dto/Quote'
import {
  CompanyPaymentGateway,
  PaymentGatewayConvertPayload,
  TokenizeCreditCardPayload,
} from '@/models/PaymentGateway'
import { PaymentGateway } from '@/services/paymentGatewayService'

export const getCardType = (cardNumber: string): string => {
  if (!cardNumber) {
    return 'invalid'
  }

  if (cardNumber[0] === '5') {
    return 'mastercard'
  } else if (cardNumber[0] === '4') {
    return 'visa'
  } else if (cardNumber[0] === '6') {
    return 'discover'
  } else if (['34', '37'].includes(cardNumber.substring(0, 2))) {
    return 'amex'
  } else if (['30', '36', '38'].includes(cardNumber.substring(0, 2))) {
    return 'diners'
  } else {
    return 'default'
  }
}

export const paymentGatewayLabels = {
  auth_net: 'Authorize.net',
  square: 'Square',
  stripe: 'Stripe',
  busify_pay: 'Busify Pay',
}

export const tokenizeAuthNetCard = async (
  payload: TokenizeCreditCardPayload,
  tokenizationObject: Quote | Reservation | InvoiceReservation,
  selectedPaymentIntegration: CompanyPaymentGateway = null
): Promise<PaymentGatewayConvertPayload> => {
  const paymentGatewayService = new PaymentGateway()
  let tokenizedCreditCardInfo: any
  try {
    tokenizedCreditCardInfo = await paymentGatewayService.tokenize(
      payload,
      tokenizationObject,
      selectedPaymentIntegration
    )
  } catch (e) {
    const errors = e as string[]
    throw errors
  }

  return tokenizedCreditCardInfo
}

export const buildAuthNetTokenizePayload = (
  info: any
): TokenizeCreditCardPayload => {
  const cardType = getCardType(info.number)
  const cardNumber = info.number.split(' ').join('')
  const paymentMethodData: TokenizeCreditCardPayload = {
    activeMethod: 'credit_card',
    name: info.name,
    cardNumber,
    mask: info.number.slice(-4),
    securityCode: info.cvc,
    exp_date: info.expiration,
    expirationMonth: info.expiration.split('/')[0],
    expirationYear: `${info.expiration.split('/')[1]}`,
    type_label: cardType,
    address: {
      street1: info.address.street,
      street2: '',
      city: null,
      state: info.address.state,
      postal_code: info.address.zip,
      name: '',
      lat: null,
      lng: null,
      title: null,
      country: null,
    },
  }

  return paymentMethodData
}
