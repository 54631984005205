
import { Vue, Component, Prop } from 'vue-property-decorator'
import rstate from '@/store/modules/reservation'
import qstate from '@/store/modules/quote'
import { currencyFilter, formatFullName } from '@/utils/string'
import { getRecurringAmountDueNow } from '@/utils/trip'

@Component({})
export default class ReservationPaymentSidebarHeader extends Vue {
  @Prop({ default: false, type: Boolean }) isReservation!: boolean

  get customerName(): string {
    if (this.isReservation) {
      return rstate.reservation.customerName
    }
    return formatFullName(qstate.quote?.customer)
  }

  get dueNow(): number {
    if (this.isReservation) {
      return rstate.reservation.balance
    }
    const totalDueNow = qstate.quote.trips.reduce(
      (totalDue, trip) => totalDue + getRecurringAmountDueNow(trip),
      0
    )
    return totalDueNow
  }

  get dueNowAmount(): string {
    if (this.isReservation) {
      return currencyFilter(rstate.reservation.balance)
    }
    return currencyFilter(this.dueNow)
  }

  get dueNowHeader(): string {
    return this.isReservation ? 'Balance' : 'Due Now'
  }

  get idHeader(): string {
    return this.isReservation ? 'Reservation' : 'Quote'
  }

  get quoteId(): string {
    return qstate.quote?.managedId
  }

  get reservationManagedId(): string {
    return rstate.reservation?.managedId
  }
}
