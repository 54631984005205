
import { Address } from '@/models/dto'
import { SquareCardFormPaymentInfo } from '@/models/PaymentGateway'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { isDevEnvironment, isAutoEnvironment } from '@/utils/env'

@Component({})
export default class CheckoutSquareCardForm extends Vue {
  @Prop({ type: String, default: undefined }) applicationId!: string | undefined
  @Prop({ required: false }) firstName: string
  @Prop({ required: false }) lastName: string
  @Prop({ required: false }) amount: number
  @Prop({ type: Boolean, default: false }) shouldVerify!: boolean
  @Prop({ required: false }) address: Partial<Address>

  errs: string[] = []
  card = null
  loading = false
  payments = null

  async mounted(): Promise<void> {
    this.loading = true

    // TODO: Replace hard-coded locationId
    // This is hard-coded for now, corresponds with Peters square account - sandbox
    let locationId = 'LVHQ9JV7848KF'
    if (isDevEnvironment() || isAutoEnvironment()) {
      locationId = 'L44FFNAYCGFX1'
    }
    const SquareCDN = window['Square']

    this.payments = SquareCDN.payments(this.applicationId, locationId)
    const card = await this.payments.card()
    this.card = card
    await card.attach('#card-container')
    this.loading = false
  }

  async verifyBuyer(result: any): Promise<any> {
    const addressLines = []
    if (this.address) {
      addressLines.push(`${this.address.street1}`)
      if (this.address.street2) {
        addressLines.push(`${this.address.street2}`)
      }
    }

    const verificationDetails = {
      amount: `${this.amount}`,
      /* collected from the buyer */
      billingContact: {
        addressLines,
        familyName: this.lastName,
        givenName: this.firstName,
        country: 'US',
        city: this.address?.city,
        state: this.address?.state,
        postalCode: this.address?.postalCode,
      },
      currencyCode: 'USD',
      intent: 'CHARGE',
    }
    const verificationResults = await this.payments.verifyBuyer(
      result.token,
      verificationDetails
    )
    return verificationResults.token
  }

  async requestCardNonce(): Promise<void> {
    try {
      const result = await this.card.tokenize()

      if (result.errors?.length > 0) {
        const errors = result.errors.map((err) => err.message).join('; ')
        throw Error(errors)
      }

      const cardData: SquareCardFormPaymentInfo = {
        exp_month: result?.details?.card?.expMonth,
        exp_year: result?.details?.card?.expYear,
        last_4: result?.details?.card?.last4,
        billing_postal_code: result?.details?.billing?.postalCode,
        card_brand: result?.details?.card?.brand,
        nonce: result?.token,
      }

      if (this.shouldVerify) {
        const verificationToken = await this.verifyBuyer(result)
        cardData.verificationToken = verificationToken
      }

      this.$emit('square-payment:card-nonce', cardData)
    } catch (e) {
      console.log(e)
      this.$emit('error')
      return
    }
  }
}
