
import sidebar from '@/store/modules/sidebar'
import quote from '@/services/quotes'
import quoteState from '@/store/modules/quote'
import { Vue, Component } from 'vue-property-decorator'
import user from '@/services/user'
import RecipientList from '@/components/RecipientList.vue'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { RecipientList },
})
export default class QuoteDetailConfirmConvert extends Vue {
  contacts = []
  isLoading = false

  close(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    this.isLoading = true
    const emails = this.contacts.map((c) => c.email)
    try {
      await quote.sendConfirmationEmail(quoteState.quote.quoteId, emails)
      EventBus.$emit('snackbar:success', 'Email sent successfully!')
      this.close()
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.message || 'Error sending quote confirmation'
      EventBus.$emit('snackbar:error', errorMessage)
    } finally {
      this.isLoading = false
    }
  }

  removeContact(index: number): void {
    this.contacts.splice(index, 1)
  }

  async mounted(): Promise<void> {
    this.contacts.push({ ...quoteState.quote.customer, type: 'Booking' })
    if (quoteState.quote.billingCustomerId) {
      const { data: billingCustomer } = await user.byId(
        quoteState.quote.billingCustomerId
      )
      this.contacts.push({ ...billingCustomer.user, type: 'Billing' })
    }
  }
}
