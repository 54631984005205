
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import qstate from '@/store/modules/quote'
import { capitalizeFirstLetter, currencyFilter } from '@/utils/string'
import { isNotEmptyInput, isCardNumberValidLength } from '@/utils/validators'
import payments from '@/services/payments'
import quotes from '@/services/quotes'
import {
  paymentGatewayLabels,
  tokenizeAuthNetCard,
  buildAuthNetTokenizePayload,
} from '@/utils/payment'
import RecipientList from '@/components/RecipientList.vue'
import {
  CustomerPaymentProfile,
  PaymentInstrument,
} from '@/models/dto/PaymentProfile'
import {
  PaymentGatewayConvertPayload,
  SquareCardFormPaymentInfo,
  TokenizeCreditCardPayload,
} from '@/models/PaymentGateway'
import sidebar from '@/store/modules/sidebar'
import SquareCardForm from '@/components/SquareCardForm.vue'
import auth from '@/store/modules/auth'
import { PaymentTypeIdKeys } from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import PaymentSidebarBulkReservationsHeader from '@/components/PaymentSidebarBulkReservationsHeader.vue'
import ProcessingFeePercentageField from '@/components/ProcessingFeePercentageField.vue'
import customer from '@/services/customer'
import currency from 'currency.js'
import { busifyPayBaseUrl } from '@/utils/env'
import BusifyPayQuoteConvertForm from '@/components/BusifyPayQuoteConvertForm.vue'
import { WindowMessage } from '@/models/Message'
import { v4 } from 'uuid'
import BusifyPayReservationPaymentForm from '@/components/BusifyPayReservationPaymentForm.vue'
import ConvertQuotePaymentSidebarPaymentFields from '@/components/ConvertQuotePaymentSidebarPaymentFields.vue'
import ConvertQuotePaymentSidebarAuthNetFields from '@/components/ConvertQuotePaymentSidebarAuthNetFields.vue'
import PaymentSidebarNoteAndRecipients from '@/components/PaymentSidebarNoteAndRecipients.vue'
import PaymentLoadingScreen from '@/components/PaymentLoadingScreen.vue'
import { InstrumentType } from '@/utils/enum'
@Component({
  components: {
    RecipientList,
    SquareCardForm,
    PaymentSidebarBulkReservationsHeader,
    PaymentSidebarHeader,
    ProcessingFeePercentageField,
    BusifyPayQuoteConvertForm,
    BusifyPayReservationPaymentForm,
    ConvertQuotePaymentSidebarPaymentFields,
    ConvertQuotePaymentSidebarAuthNetFields,
    PaymentSidebarNoteAndRecipients,
    PaymentLoadingScreen,
  },
})
export default class ConvertQuoteWithPaymentSidebar extends Vue {
  currencyFilter = currencyFilter
  isNotEmptyInput = isNotEmptyInput
  isCardNumberValidLength = isCardNumberValidLength
  auth = auth
  DEFAULT_PROCESSING_FEE = 3

  displayConversionError = false

  paymentIntegrations = []
  contacts = []
  customerPaymentProfiles: Partial<CustomerPaymentProfile>[] = [
    { customerPaymentProfileId: -1, text: 'Add New Card' },
  ]

  loading = false
  sessionId = ''

  formData = {
    paymentAmount: '0',
    processingFeePercentage: 0,
    paymentNotes: '',
    internalNotes: '',
    number: '',
    expiration: '',
    cvc: '',
    name: '',
    address: {
      street: null,
      state: null,
      zip: null,
    },
    savePaymentMethod: false,
    selectedPaymentProfile: -1,
    sendEmailToCustomer: true,
    selectedPaymentIntegration: null,
    selectedPaymentMethod: 'card',
  }

  get quoteId(): number {
    return qstate?.quote?.quoteId
  }

  get paymentType(): number {
    return Number(this.formData.paymentAmount) === qstate.quote.amount
      ? PaymentTypeIdKeys.FULL_PAYMENT
      : PaymentTypeIdKeys.DOWN_PAYMENT
  }

  get customerId(): number {
    return qstate.quote.customerId
  }

  get billingCustomerId(): number {
    return qstate.quote.billingCustomerId
  }

  get processingFeeAmount(): number {
    return currency(this.formData.paymentAmount)
      .multiply(this.formData.processingFeePercentage)
      .divide(100).value
  }

  get totalPaymentAmount(): number {
    const processingFeeAmount = this.processingFeeAmount || 0
    return currency(this.formData.paymentAmount).add(processingFeeAmount).value
  }

  get bookingCustomerId(): number {
    return this.contacts.find((c) => c.type === 'Booking')?.customerId
  }

  get dueNow(): number {
    const totalDueNow = qstate.quote.trips.reduce(
      (totalDue, trip) => totalDue + trip.recurringAmountDueNow,
      0
    )
    return totalDueNow
  }

  get shouldAddNewCard(): boolean {
    return this.formData.selectedPaymentProfile === -1
  }

  get isSquare(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'square'
    )
  }

  get isBusifyPay(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'busify_pay'
    )
  }

  get isAuthNet(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'auth_net'
    )
  }

  get confirmationEmails(): string[] {
    if (!this.formData.sendEmailToCustomer) {
      return []
    }
    return this.contacts.map((u) => u.email)
  }

  get clientKey(): string {
    return this.formData.selectedPaymentIntegration?.clientKey
  }

  submitBusifyPayQuoteForm(): void {
    const busifyPayCardForm: any = this.$refs['busify-pay-quote-form']
    if (!busifyPayCardForm) {
      return
    }
    busifyPayCardForm.submit()
  }

  handleBusifyPayError(errorMessage: Record<string, unknown>): void {
    this.loading = false
    this.$emit('convert-error')
    const message =
      errorMessage?.statusCode === 400
        ? errorMessage?.errorMessage
        : 'There was an error processing your payment. Please confirm all your information is correct and try again.'
    EventBus.$emit('snackbar:error', message)
  }

  handleBusifyPayInvalidForm(): void {
    this.loading = false
  }

  async handleBusifyPayQuoteSuccess(message: WindowMessage): Promise<void> {
    let description = ''
    const { paymentId } = message.messageData as any
    const { brand, instrument_type, last_four } = message.messageData
      ?.instrument as PaymentInstrument
    if (instrument_type === InstrumentType.CARD) {
      description = `${brand} - ${last_four}`
    } else {
      description = 'Online Bank'
    }

    const payment_method =
      this.formData.selectedPaymentMethod === 'card' ? 'credit_card' : 'ach'

    const payload = {
      quoteId: this.quoteId,
      paymentInfo: null,
      payment_type: this.paymentType,
      amountPaid: this.totalPaymentAmount,
      paymentMethodData: null,
      convertedBy: auth.userId,
      payment_method,
      confirmationEmails: this.confirmationEmails,
      processingFeePercentage: this.formData.processingFeePercentage,
      description,
      paymentId,
      sessionId: this.sessionId,
    }

    try {
      const res = await quotes.convertQuoteWithBusifyPay(payload)
      if (res.status === 200) {
        this.handleSubmitSuccess()
      } else {
        throw new Error('Error converting quote')
      }
    } catch (e) {
      // If there's an error converting the quote, display the error message and scroll to the error
      EventBus.$emit('snackbar:error', 'Error converting quote')
      this.displayConversionError = true
      this.$nextTick(() => {
        document
          .getElementById('busify-pay-quote-error')
          .scrollIntoView({ behavior: 'smooth' })
      })
    } finally {
      this.loading = false
    }
  }

  handleMessageEvent(event: MessageEvent): void {
    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }
    if (data.messageName === 'payment-failure') {
      this.handleBusifyPayError(data.messageData)
    } else if (data.messageName === 'payment-success') {
      this.handleBusifyPayQuoteSuccess(event.data)
    } else {
      this.handleBusifyPayInvalidForm()
    }
  }

  // Initial loading methods
  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    let { paymentGateways: installedGateways } = allGatewaysRes.data
    installedGateways = installedGateways.map((g) => {
      return {
        ...g,
        paymentGatewayLabel: paymentGatewayLabels[g.paymentGatewayTypeKey],
      }
    })

    if (!installedGateways.length) {
      return
    }

    this.paymentIntegrations = installedGateways

    const defaultPaymentGateway = installedGateways.find(
      (g) => g.defaultGateway
    )

    this.formData.selectedPaymentIntegration =
      defaultPaymentGateway || this.paymentIntegrations[0]
  }

  async loadCustomerPaymentProfiles(): Promise<void> {
    let customerIds = []

    customerIds = [this.customerId]
    if (this.billingCustomerId) {
      customerIds.push(this.billingCustomerId)
    }

    const res = await payments.getCustomerPaymentProfiles(
      { pageSize: -1, page: 1 },
      customerIds
    )

    const paymentProfiles = res.data.resultList.map((p) => ({
      ...p,
      text: `${capitalizeFirstLetter(p.label)} ending in ${p.mask}`,
    }))
    this.customerPaymentProfiles =
      this.customerPaymentProfiles.concat(paymentProfiles)
  }

  loadProcessingFee(): void {
    const cardPaymentMethodInfo = qstate.quote.paymentMethods.find(
      (method) => method.paymentMethodType.key === 'credit_card'
    )
    if (cardPaymentMethodInfo?.processingFee) {
      this.formData.processingFeePercentage =
        cardPaymentMethodInfo.processingFee
      return
    }
    this.formData.processingFeePercentage = this.DEFAULT_PROCESSING_FEE
  }

  async loadContacts(): Promise<void> {
    const bookingContact = qstate.quote.customer

    this.contacts.push({ ...bookingContact, type: 'Booking' })
    if (this.billingCustomerId) {
      const { data: billingCustomer } = await customer.byId(
        this.billingCustomerId
      )
      this.contacts.push({ ...billingCustomer.customer, type: 'Billing' })
    }
  }

  loadDueNow(): void {
    this.formData.paymentAmount = this.dueNow.toFixed(2)
  }
  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    this.$emit('convert-quote')
  }

  // Submit Methods
  async convertQuote(): Promise<void> {
    this.loading = true
    if (this.isAuthNet) {
      if (this.shouldAddNewCard) {
        const authNetTokenizePayload = buildAuthNetTokenizePayload(
          this.formData
        )
        try {
          const tokenizedCardInfo: PaymentGatewayConvertPayload =
            await tokenizeAuthNetCard(
              authNetTokenizePayload,
              qstate.quote,
              this.formData.selectedPaymentIntegration
            )
          await this.convertQuoteWithCard(
            tokenizedCardInfo,
            authNetTokenizePayload
          )
        } catch (e: any) {
          const errorMessage = e?.[0] || 'Error processing payment'
          EventBus.$emit('snackbar:error', errorMessage)
          this.loading = false
        }
      } else {
        this.convertQuoteWithExistingProfile()
      }
    } else if (this.isSquare) {
      await this.tokenizeSquareCard()
    } else if (this.isBusifyPay) {
      this.submitBusifyPayQuoteForm()
    }
  }

  async tokenizeSquareCard(): Promise<void> {
    const squarePaymentForm: any = this.$refs['square-payment-form']
    if (!squarePaymentForm) {
      return
    }

    try {
      await squarePaymentForm.requestCardNonce()
    } catch (e) {
      this.$emit('convert-error')
      this.loading = false
      return
    }
  }

  async handleSquareNonce(
    paymentInfo: SquareCardFormPaymentInfo
  ): Promise<void> {
    const { nonce } = paymentInfo
    const tokenizedPaymentInfo: PaymentGatewayConvertPayload = {
      tokens: [nonce],
      paymentGateway: {
        key: 'square',
        id: this.formData.selectedPaymentIntegration.companyPaymentGatewayId,
        clientId: this.clientKey,
      },
    }

    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: '',
      cardholderName: '',
      cardNumber: '',
      securityCode: '',
      mask: paymentInfo.last_4,
      type_label: paymentInfo.card_brand,
      ...paymentInfo,
    }

    await this.convertQuoteWithCard(tokenizedPaymentInfo, paymentMethodData)
  }

  handleSubmitSuccess(): void {
    this.loading = false
    sidebar.close()

    this.$router.push({ name: 'reservations' })
  }

  async convertQuoteWithCard(
    tokenizedCardInfo: PaymentGatewayConvertPayload,
    paymentMethodInfo: TokenizeCreditCardPayload
  ): Promise<void> {
    try {
      const res = await quotes.convertQuoteWithTokenizedPayment({
        quoteId: this.quoteId,
        paymentInfo: tokenizedCardInfo,
        payment_type: this.paymentType,
        amountPaid: this.totalPaymentAmount,
        paymentMethodData: paymentMethodInfo,
        confirmationEmails: this.confirmationEmails,
        customer_notes: this.formData.paymentNotes,
        internal_notes: this.formData.internalNotes,
        processingFeePercentage: this.formData.processingFeePercentage,
        convertedBy: auth.userId,
        overrideExpiration: true,
        disableInvoiceCreation: qstate.disableQuickbooksInvoiceCreation,
      })
      if (res.status === 200) {
        this.handleSubmitSuccess()
      } else {
        const errorMessage = res?.data?.message || 'Error processing payment'
        this.$emit('convert-error')
        EventBus.$emit('snackbar:error', errorMessage)
      }
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.message || 'Error processing payment'
      EventBus.$emit('snackbar:error', errorMessage)
      this.$emit('convert-error')
    } finally {
      this.loading = false
    }
  }

  async convertQuoteWithExistingProfile(): Promise<void> {
    const profile = this.customerPaymentProfiles.find(
      (p) => p.customerPaymentProfileId === this.formData.selectedPaymentProfile
    )
    const description =
      profile.mask && profile.label
        ? `Card - ${capitalizeFirstLetter(profile.label)} ${profile.mask}`
        : null
    try {
      const res = await quotes.convertQuoteWithCustomerPaymentProfile(
        this.quoteId,
        this.formData.selectedPaymentProfile,
        {
          id: this.formData.selectedPaymentIntegration.companyPaymentGatewayId,
          key: this.formData.selectedPaymentIntegration.paymentGatewayTypeKey,
        },
        this.paymentType,
        this.totalPaymentAmount,
        description,
        this.confirmationEmails,
        this.formData.paymentNotes,
        this.formData.internalNotes,
        this.formData.processingFeePercentage,
        auth.userId,
        true,
        qstate.disableQuickbooksInvoiceCreation
      )
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.message || 'Error processing payment'
      EventBus.$emit('snackbar:error', errorMessage)
    } finally {
      this.loading = false
    }
  }

  created(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }

  async mounted(): Promise<void> {
    await this.loadPaymentGateways()
    await this.loadContacts()
    await this.loadCustomerPaymentProfiles()
    this.loadProcessingFee()
    this.loadDueNow()

    this.sessionId = v4()
  }
}
