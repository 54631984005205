
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Trip, Vehicle } from '@/models/dto'
import { tripPickupTime, tripPickupTimeZone } from '@/utils/trip'

@Component({})
export default class TripDetailsGrid extends mixins(DateMixin) {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, default: false }) readonly horizontal!: boolean

  get recurrenceCount(): string {
    return this.trip.recurrenceTripCount?.toString() || '--'
  }

  get vehicles(): Vehicle[] {
    return (this.trip.vehicles || []).filter(
      (vehicle) => vehicle.quantity && vehicle.vehicleType
    )
  }

  get drivers(): string {
    const count = this.trip.requiredDrivers
    return count ? String(count) : '--'
  }

  get passengers(): string {
    const count = this.trip.passengerCount
    return count ? String(count) : '--'
  }

  get tripType(): string {
    return this.trip.tripType?.label || '--'
  }

  get pickupDate(): string {
    const pickupTime = tripPickupTime(this.trip)
    const pickupTimeZone = tripPickupTimeZone(this.trip)
    return !pickupTime
      ? '--'
      : this.formatShortDate(pickupTime, { tz: pickupTimeZone })
  }
}
