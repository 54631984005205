
import sidebar from '@/store/modules/sidebar'
import quote from '@/services/quotes'
import quoteState from '@/store/modules/quote'
import { Vue, Component } from 'vue-property-decorator'
import user from '@/services/user'
import RecipientList from '@/components/RecipientList.vue'
import auth from '@/store/modules/auth'
import { PaymentTypeIdKeys } from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import QuickbooksConvertQuoteSidebar from './QuickbooksConvertQuoteSidebar.vue'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({
  components: { RecipientList },
})
export default class QuoteDetailConfirmConvert extends Vue {
  contacts = []
  sendBookingConfirmation = true
  auth = auth
  isLoading = false

  close(): void {
    sidebar.pop()
  }

  handleQuoteSubmit(): void {
    if (auth.isQuickBooksAutomaticInvoiceCreation) {
      sidebar.push({
        component: QuickbooksConvertQuoteSidebar,
        title: 'Create QuickBooks Invoice',
        props: {
          userId: quoteState.quote.customerId,
        },
        on: {
          'convert-quote': this.submit,
        },
      })
      return
    } else {
      this.submit()
    }
  }

  async submit(): Promise<void> {
    this.isLoading = true
    let emails = []
    if (this.sendBookingConfirmation) {
      emails = this.contacts.map((c) => c.email)
    }
    try {
      await quote.convertManually({
        quoteId: quoteState.quote.quoteId,
        payment_method: null,
        payment_type: PaymentTypeIdKeys.BILL_AFTER_SERVICES,
        amountPaid: 0,
        confirmationEmails: emails,
        convertedBy: auth.userId,
        overrideExpiration: true,
        enablePONumber: quoteState.quote.enablePONumber,
        poNumber: quoteState.quote.poNumber,
      })
      this.close()
      if (auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.RESERVATIONS)) {
        this.$router.push({ name: 'reservations' })
      } else {
        this.$router.push({ name: 'quotes' })
      }
    } catch (e: any) {
      const errorMessage =
        e?.response?.data?.message || 'Error converting quote'
      EventBus.$emit('snackbar:error', errorMessage)
    } finally {
      this.isLoading = false
    }
  }

  removeContact(index: number): void {
    this.contacts.splice(index, 1)
  }

  async mounted(): Promise<void> {
    this.contacts.push({ ...quoteState.quote.customer, type: 'Booking' })
    if (quoteState.quote.billingCustomerId) {
      const { data: billingCustomer } = await user.byId(
        quoteState.quote.billingCustomerId
      )
      this.contacts.push({ ...billingCustomer.user, type: 'Billing' })
    }
  }
}
