
import { PaymentMethod } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PaymentLinkCell extends Vue {
  @Prop({ required: true, default: null }) item: PaymentMethod

  get methodType(): string {
    return this.item.paymentMethodType?.label
  }

  handleLinkClick(): void {
    if (!this.item.isLinkToSettingsIntegrations) {
      return
    }
    this.$router.push({ name: 'settings.integrations' })
  }
}
