
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class CUPercentage extends Vue {
  get isValueNull(): boolean {
    return this.$attrs.value == null || this.$attrs.value === ''
  }

  onKeyDown(e: KeyboardEvent): void {
    switch (e.key) {
      case 'Backspace':
        return
      case 'e': // e
      case '+': // +
      case '-': // -
        e.preventDefault()
        break
      case '.': // .
        if (this.$attrs.value?.length >= 4) {
          e.preventDefault()
        }
        break
    }

    const valueAsString = (this.$attrs.value || '').toString()
    if (
      valueAsString[valueAsString.length - 2] === '.' &&
      isFinite(Number(e.key))
    ) {
      e.preventDefault()
    }
  }
}
