
import { Vue, Component } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'

@Component({})
export default class QuotePaymentAmountSidebar extends Vue {
  amount: number = null
  loading = false

  clear(): void {
    this.loading = false
    sidebar.pop()
  }

  submit(): void {
    this.$emit('save', this.amount)
    this.loading = true
  }
}
