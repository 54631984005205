
import { Vue, Component } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'

@Component({})
export default class QuotePaymentPercentageSidebar extends Vue {
  percentage: number = null
  loading = false

  clear(): void {
    this.loading = false
    sidebar.pop()
  }

  submit(): void {
    this.$emit('save', Number(this.percentage))
    this.loading = true
  }
}
